import React, {useEffect, useState} from 'react';
import selfi from "./img/selfi.png";
import axios from "axios";
import "./css/kyc.css"

import FormData from "form-data"
import {closeSnackbar, enqueueSnackbar} from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import {grey, yellow} from "@mui/material/colors";
const Kyc = () => {
    const [selectedOption, setSelectedOption] = useState("Национальный паспорт");
    const [series, setSeries] = useState('');
    const [fullName, setFullName] = useState('');
    const [backDocumentFile, setBackDocumentFile] = useState(null);
    const [frontDocumentFile, setFrontDocumentFile] = useState(null);
    const [selfieFile, setSelfieFile] = useState(null);
    const [placeHolder, setPlaceHolder] = useState("AA 1234567");
    const [kycStatus, setKycStatus] = useState("");




    const action = snackbarId => (
        <button onClick={() => { closeSnackbar(snackbarId) }}>
            <CloseIcon/>
        </button>
    );


    const token = localStorage.getItem('token');


    const kysStatus = () => {
        axios.get("https://api.fenixodex.com/api/api/kyc/status", {
            headers: {
                "Authorization" : `Bearer ${token}`
            }
        })
            .then(res => {
                setKycStatus(res.data)
                setTimeout(kysStatus, 5000)
            })
    }

    useEffect(() => {
        kysStatus()
    }, [])

    const form = new FormData();

    if (backDocumentFile) {
        form.append('files', backDocumentFile);
    }

    if (frontDocumentFile) {
        form.append('files', frontDocumentFile);
    }

    if (selfieFile) {
        form.append('files', selfieFile);
    }

// Выполняем POST-запрос с использованием Axios

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSeriesChange = (event) => {
        setSeries(event.target.value);
    };

    const handleFullNameChange = (event) => {
        setFullName(event.target.value);
    };

    const handleBackDocumentFileChange = (event) => {
        const file = event.target.files[0];
        setBackDocumentFile(file);
    };

    const handleFrontDocumentFileChange = (event) => {
        const file = event.target.files[0];
        setFrontDocumentFile(file);
    };

    const handleSelfieFileChange = (event) => {
        const file = event.target.files[0];
        setSelfieFile(file);
    };

    const headers = {
        'accept': 'application/json',
        "Authorization" : `Bearer ${token}`,
        'Content-Type': `multipart/form-data; boundary=${form._boundary}`
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: yellow[600],
            },
        },
    });
    const handleSubmit = () => {

        axios.post('https://api.fenixodex.com/api/api/kyc/upload', form, {
            headers: headers
        }
        )
            .then(response => {
                axios.post('https://api.fenixodex.com/api/api/kyc/details', {
                    "document_type": selectedOption,
                    "passport_data": series,
                    "patronymic": fullName
                }, {headers: {"Authorization" : `Bearer ${token}`}})
                    .then(() => {

                        enqueueSnackbar(`Заявка отправленна!`, {action, 'variant': 'success', 'className': "snacksuccess"});
                    })
            })
            .catch(error => {
                enqueueSnackbar(`Возникла ошибка: ${error.response.data.detail}`, {action, 'variant': 'error', 'className': "snackerror"});
            });
    };

    useEffect(() => {
        if (selectedOption === "Национальный паспорт"){
            setPlaceHolder("1234 123456")
        }
        else if (selectedOption === "Заграничный паспорт"){
            setPlaceHolder("70 1234567")
        }
        else if (selectedOption === "Водительское удостоверение"){
            setPlaceHolder("70 70 123456")
        }
    }, [selectedOption])

    return (
        <div className="tabs__body">
            <div className={`deposit-popup-window ${kycStatus === "waitingwaiting" ? "" : "hidden"}`}>
                <div className="deposit-popup" >
                    <p className="dp-pp-text">В ожидании проверки</p>
                    <ThemeProvider theme={theme}>
                        <CircularProgress />
                    </ThemeProvider>

                </div>

            </div>
            <div className="account__box">
                <div className="kyc__box">
                    <div className="account__balans">KYC Верификация</div>
                    { kycStatus == "accepted"
                    ? <p className="already">Вы уже верифицированы!</p>
                    : kycStatus === "waiting" ? <div className="center-do">
                            <p className="dp-pp-text2">Ваши документы отправлены, ожидайте проверки</p>
                            <ThemeProvider theme={theme}>
                                <CircularProgress />
                            </ThemeProvider></div>
                    : <>

                            <div className="kyc__tip">
                                <select name="form[]" className="select1" value={selectedOption} onChange={handleOptionChange}>
                                    <option value={"Национальный паспорт"}>Национальный паспорт</option>
                                    <option value={"Заграничный паспорт"}>Заграничный паспорт</option>
                                    <option value={"Водительское удостоверение"}>Водительское удостоверение</option>
                                </select>
                            </div>
                            <div className="kyc__inputs">
                                <div className="kyc__item">
                                    <p>Серия</p>
                                    <input type="text" placeholder={placeHolder} value={series} onChange={handleSeriesChange} />
                                </div>
                                <div className="kyc__item">
                                    <p>Полное имя</p>
                                    <input type="text" placeholder="Иван Иванов" value={fullName} onChange={handleFullNameChange} />
                                </div>
                            </div>
                            <div className="kyc__block">
                                <div className="kyc__title2">Загрузить удостоверение</div>
                                <div className="kyc__box2">
                                    <div className="kyc__itemm">
                                        <p className="malina">Задняя сторона документа</p>
                                        <div className="kyc__b-item">
                                            <input type="file" name="" id="" onChange={handleBackDocumentFileChange} />
                                            <p className="kyc__name">
                                                {backDocumentFile ? backDocumentFile.name : "Переместите файл или Найти"}
                                            </p>
                                            <p className="kyc__sub">
                                                Макс. размер изображения - 10 мб
                                            </p>
                                        </div>
                                    </div>
                                    <div className="kyc__itemm">
                                        <p className="malina">Передняя сторона документа</p>
                                        <div className="kyc__b-item">
                                            <input type="file" name="" id="" onChange={handleFrontDocumentFileChange} />
                                            <p className="kyc__name">
                                                {frontDocumentFile ? frontDocumentFile.name : "Переместите файл или Найти"}
                                            </p>
                                            <p className="kyc__sub">
                                                Макс. размер изображения - 10 мб
                                            </p>
                                        </div>
                                    </div>
                                    <div className="kyc__itemm">
                                        <p className="malina">Селфи с документом</p>
                                        <div className="kyc__b-item">
                                            <input type="file" name="" id="" onChange={handleSelfieFileChange} />
                                            <img src={selfi} alt="" />
                                            <p className="kyc__sub">
                                                Макс. размер изображения - 10 мб
                                            </p>
                                            <p className="kyc__name">
                                                {selfieFile ? selfieFile.name : "Найти"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="lineeee" />
                            <button className="btn56" onClick={handleSubmit}>Отправить</button>

                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default Kyc;
