import React from 'react';
import './footer.css'
import {NavLink} from "react-router-dom";
import logo from '../../images/FenixoDEX logo.svg'
const Footer = () => {
    return (
        <footer className="footer-m">
            <div className="footer-container">
                <div className="footer-section">
                    <NavLink to="/main"><img src={logo} alt=""/></NavLink>
                    <div className="rights">FenixoDEX © 2021-2024</div>
                </div>

                <div className="footer-section">
                    <NavLink to='/about' className="footer-link">О нас</NavLink>
                    <NavLink to='/specialists' className="footer-link">Команда аналитиков</NavLink>
                </div>

                <div className="footer-section">

                    <NavLink to='/privacy' className="footer-link">Политика конфиденциальности</NavLink>
                    <NavLink to='/contacts' className="footer-link">Контакты</NavLink>
                </div>
            </div>
        </footer>
    );
};

export default Footer;