import React from 'react';
import "./order.sass"
const Order = (props) => {
    const setPriceSell = () => {
        props.setPropsPrice(props.price)
    }
    const setPriceBuy = () => {
        props.setPropsPriceSell(props.price)
    }
    const {price, qty, text} = props;
    let total = price * qty ;
    (function() {
        function decimalAdjust(type, value, exp) {
            if (typeof exp === 'undefined' || +exp === 0) {
                return Math[type](value);
            }
            value = +value;
            exp = +exp;
            if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
                return NaN;
            }
            value = value.toString().split('e');
            value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
            value = value.toString().split('e');
            return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
        }

        if (!Math.floor10) {
            Math.floor10 = function(value, exp) {
                return decimalAdjust('floor', value, exp);
            };
        }
    })();
    let priceDivided = price[0] + price[1] + price[2] + price[3] + price[4] + price[5] + price[6] + price[7];
    return (
        <div className="bin__c-line" onClick={ props.asking ? setPriceSell : setPriceBuy}>
            <div className={`bin__c-name ${text}`}>
                <span>{priceDivided}</span>
            </div>
            <div className="bin__c-price">{qty}</div>
            <div className={`bin__c-percent `}>{(Math.floor10(total, -3))}</div>
        </div>

    );
};

export default Order;
