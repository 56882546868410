import React from 'react';
import "../order.sass"
const Order = (props) => {
    const setPriceSell = () => {
        props.setPropsPrice(props.price)
    }
    const setPriceBuy = () => {
        props.setPropsPriceSell(props.price)
    }
    const {price, qty, text, stocks} = props;
    let total = price * qty ;
    (function() {
        function decimalAdjust(type, value, exp) {
            if (typeof exp === 'undefined' || +exp === 0) {
                return Math[type](value);
            }
            value = +value;
            exp = +exp;
            if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
                return NaN;
            }
            value = value.toString().split('e');
            value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
            value = value.toString().split('e');
            return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
        }

        if (!Math.floor10) {
            Math.floor10 = function(value, exp) {
                return decimalAdjust('floor', value, exp);
            };
        }
    })();
    let priceString = price.toString()
    let priceDivided = priceString[0] + priceString[1] + priceString[2] + priceString[3] + priceString[4] + priceString[5] + priceString[6] + priceString[7];

    return (
        <div className="bin__c-line" onClick={ props.asking ? setPriceSell : setPriceBuy}>
            <div className={`bin__c-name ${text}`}>
                <span>{stocks ? priceDivided : priceDivided }</span>
            </div>
            <div className="bin__c-price">{qty}</div>
            <div className={`bin__c-percent `}>{(Math.floor10(total, -3))}</div>
        </div>

    );
};

export default Order;
