import React from 'react';
import axios from "axios";

const GridBalance = (props) => {


    return (
        <div className="grid-item"><div className="grid-flex"><img src={`https://api.fenixodex.com/api/static/icons/${props.coin}.png`} alt="" className="grid-img"/> <p className="grid-children"> {props.coin.toUpperCase()}</p></div>  <p className="grid-children">{Math.floor10(props.value, -5)}</p> </div>
    );
};

export default GridBalance;