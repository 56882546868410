import React from 'react';
import USDTtr from "./usdttr";
import Loading from "./loading/loading";
import {useEffect, useState} from "react";
import axios from "axios";
import srch from './verstka(temp)/img/search.svg?_v=1689845484602'
import zvezda from './verstka(temp)/img/zvezd.svg?_v=1689845484602'
import right from './verstka(temp)/img/right.svg?_v=1689845484602'
import cotir from './verstka(temp)/img/cotir.svg?_v=1689845484602'
import {useNavigate} from "react-router-dom";
const PairsList = () => {
    let activeCoinLS =  localStorage.getItem("activeCoin") ?  localStorage.getItem("activeCoin") : "usdt"
    const navigate = useNavigate()
    const [isLoaded, setIsLoaded] = useState(false);
    const [query, setQuery] = useState("")
    const [activeCoin, setActiveCoin] = useState(activeCoinLS);
    const [pairsUsdt, setPairsUsdt] = useState([]);
    const [pairsBtc, setPairsBtc] = useState([]);
    const [pairsBnb, setPairsBnb] = useState([]);
    const [pairsStocks, setPairsStocks] = useState([
        {
            "symbol": "GAZPRUB",
            "priceChangePercent": "0",
            "lastPrice": "0",
            "frame": "ru:gazp?id=ru:gazp-frame-3485933083"
        }
        , {
            "symbol": "ALRSRUB",
            "priceChangePercent": "0",
            "lastPrice": "0",
            "frame": "ru:alrs?id=ru:alrs-frame-3485933083"
        }
        , {
            "symbol": "WUSHRUB",
            "priceChangePercent": "0",
            "lastPrice": "0",
            "frame": "ru:wush?id=ru:wush-frame-3485933083"
        }
        , {
            "symbol": "LKOHRUB",
            "priceChangePercent": "0",
            "lastPrice": "0",
            "frame": "ru:lkoh?id=ru:lkoh-frame-3485933083"
        }
        , {
            "symbol": "MOEXRUB",
            "priceChangePercent": "0",
            "lastPrice": "0",
            "frame": "ru:moex?id=ru:moex-frame-3485933083"
        }
        , {
            "symbol": "MTSSRUB",
            "priceChangePercent": "0",
            "lastPrice": "0",
            "frame": "ru:mtss?id=ru:mtss-frame-3485933083"
        }
        , {
            "symbol": "BELURUB",
            "priceChangePercent": "0",
            "lastPrice": "0",
            "frame": "ru:belu?id=ru:belu-frame-3485933083"
        }
        , {
            "symbol": "SBERRUB",
            "priceChangePercent": "0",
            "lastPrice": "0",
            "frame": "ru:sber?id=ru:sber-frame-3485933083"
        }
        , {
            "symbol": "SBERPRUB",
            "priceChangePercent": "0",
            "lastPrice": "0",
            "frame": "ru:sberp?id=ru:sberp-frame-3485933083"
        }
        , {
            "symbol": "TATNRUB",
            "priceChangePercent": "0",
            "lastPrice": "0",
            "frame": "ru:tatn?id=ru:tatn-frame-3485933083"
        }
        , {
            "symbol": "TRNFPRUB",
            "priceChangePercent": "0",
            "lastPrice": "0",
            "frame": "ru:trnfp?id=ru:trnfp-frame-3485933083"
        }

        ]
    );
    const [pairsResources, setPairsResources] = useState([
        {
            "symbol": "GOLDUSD",
            "priceChangePercent": "0",
            "lastPrice": "0",
            "frame": "TVC:GOLD",
        }
        , {
            "symbol": "SILVERUSD",
            "priceChangePercent": "0",
            "lastPrice": "0",
            "frame": "TVC:SILVER",
        }
        , {
            "symbol": "COPPERUSD",
            "priceChangePercent": "0",
            "lastPrice": "0",
            "frame": "CAPITALCOM:COPPER",
        }
        // , {
        //     "symbol": "XAUUSD",
        //     "priceChangePercent": "0",
        //     "lastPrice": "0",
        //     "frame": "TSX:XAU",
        // }
        ]
    );
    const [pairsNeft, setPairsNeft] = useState([
        {
            "symbol": "BRENTUSD",
            "priceChangePercent": "0",
            "lastPrice": "0",
            "frame": "BLACKBULL:BRENT",
        }
        , {
            "symbol": "WTIUSD",
            "priceChangePercent": "0",
            "lastPrice": "0",
            "frame": "BLACKBULL:WTI",
        }
        ]
    );

    const sac = (coin) => {
        setActiveCoin(coin)
        localStorage.setItem("activeCoin", coin)
        if(coin == "stocks") {
            navigate("/market-stocks?symbol=GAZPRUB")
        }
        if(coin == "resources") {
            navigate("/market-resources?resource=GOLDUSD")
        }
        else if(coin == "usdt") {
            navigate("/market?symbol=BTCUSDT")
        }
        else if(coin == "btc") {
            navigate("/market?symbol=ETHBTC")
        }
        else if(coin == "eth") {
            navigate("/market?symbol=BTCETH")
        }
        else if(coin == "neft") {
            navigate("/market-resources?resource=BRENTUSD")
        }
    }
    const requireData = () => axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbols=["DOGEUSDT","BCHUSDT","ATOMUSDT","LDOUSDT","GRTUSDT","BTCUSDT","ADAUSDT","WBTCUSDT","APTUSDT","AAVEUSDT","ETHUSDT","SOLUSDT","AVAXUSDT","ARBUSDT","VETUSDT","STXUSDT","TRXUSDT","LINKUSDT","QNTUSDT","ALGOUSDT","BNBUSDT","LTCUSDT","XLMUSDT","ETCUSDT","NEARUSDT","IMXUSDT","XRPUSDT","UNIUSDT","FILUSDT","MKRUSDT","AXSUSDT","DOTUSDT","ICPUSDT","OPUSDT","EGLDUSDT","SHIBUSDT","HBARUSDT"]`)
        .then(res => {
            setPairsUsdt(res.data);
            setTimeout(() => requireData(), 2000)
        })
    const requireDataBtc = () => axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbols=["DOGEBTC","BCHBTC","ATOMBTC","LDOBTC","GRTBTC","ADABTC","WBTCBTC","APTBTC","AAVEBTC","ETHBTC","SOLBTC","AVAXBTC","ARBBTC","VETBTC","STXBTC","TRXBTC","LINKBTC","QNTBTC","ALGOBTC","BNBBTC","LTCBTC","XLMBTC","ETCBTC","NEARBTC","IMXBTC","XRPBTC","UNIBTC","FILBTC","MKRBTC","AXSBTC","DOTBTC","ICPBTC","OPBTC","EGLDBTC","HBARBTC"]`)
        .then(res => {
            setPairsBtc(res.data);
            setTimeout(() => requireDataBtc(), 2000)
        })
    const requireDataBnb = () => axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbols=["DOGEBNB","BCHBNB","ATOMBNB","ADABNB","OPBNB","EGLDBNB","HBARBNB","SOLBNB","AVAXBNB","VETBNB","STXBNB","TRXBNB","LINKBNB","QNTBNB","ALGOBNB","LTCBNB","XLMBNB","ETCBNB","NEARBNB","IMXBNB","XRPBNB","UNIBNB","FILBNB","MKRBNB","AXSBNB","DOTBNB","ICPBNB","AAVEBNB"]`)
        .then(res => {
            setPairsBnb(res.data);
            setTimeout(() => requireDataBnb(), 2000)
        })
    const requireDataStocks = () => {
        const updateStockData = (symbol, apiSymbol) => {
            axios.get(`https://api.fenixodex.com/api/api/share/get_avg?symbol=${apiSymbol}`)
                .then(res => {
                    // Предполагаем, что ответ имеет формат { "priceChangePercent": "-1.030", "lastPrice": "16083.42" }
                    const newData = {
                        symbol: symbol,
                        priceChangePercent: res.data.priceChangePercent,
                        lastPrice: res.data.lastPrice,
                    };

                    setPairsStocks(prevStocks =>
                        prevStocks.map(stock =>
                            stock.symbol === symbol
                                ? newData
                                : stock
                        )
                    );
                });
        };

        // Обновляем данные для каждой акции
        updateStockData('GAZPRUB', 'GAZP');
        updateStockData('ALRSRUB', 'ALRS');
        updateStockData('WUSHRUB', 'WUSH');
        updateStockData('LKOHRUB', 'LKOH');
        updateStockData('MOEXRUB', 'MOEX');
        updateStockData('MTSSRUB', 'MTSS');
        updateStockData('BELURUB', 'BELU');
        updateStockData('SBERRUB', 'SBER');
        updateStockData('SBERPRUB', 'SBERP');
        updateStockData('TATNRUB', 'TATN');
        updateStockData('TRNFPRUB', 'TRNFP');

        setTimeout(() => {
            requireDataStocks();
        }, 2000);
    };
    const requireDataResources = () => {
        const updateResourcesData = (symbol, apiSymbol) => {
            axios.get(`https://api.fenixodex.com/api/api/share/get_avg?symbol=${apiSymbol}`)
                .then(res => {
                    // Предполагаем, что ответ имеет формат { "priceChangePercent": "-1.030", "lastPrice": "16083.42" }
                    const newData = {
                        symbol: symbol,
                        priceChangePercent: res.data.priceChangePercent,
                        lastPrice: res.data.lastPrice,
                    };

                    setPairsResources(prevResources =>
                        prevResources.map(resource =>
                            resource.symbol === symbol
                                ? newData
                                : resource
                        )
                    );
                })
                .catch(error => console.error(error))
        };
        const updateNeftData = (symbol, apiSymbol) => {
            axios.get(`https://api.fenixodex.com/api/api/share/get_avg?symbol=${apiSymbol}`)
                .then(res => {
                    // Предполагаем, что ответ имеет формат { "priceChangePercent": "-1.030", "lastPrice": "16083.42" }
                    const newData = {
                        symbol: symbol,
                        priceChangePercent: res.data.priceChangePercent,
                        lastPrice: res.data.lastPrice,
                    };

                    setPairsNeft(prevResources =>
                        prevResources.map(resource =>
                            resource.symbol === symbol
                                ? newData
                                : resource
                        )
                    );
                })
                .catch(error => console.error(error))
        };

        updateResourcesData('GOLDUSD', 'GOLD');
        updateResourcesData('SILVERUSD', 'SILVER');
        updateResourcesData('COPPERUSD', 'COPPER');
        // updateResourcesData('XAUUSD', 'XAU');
        updateNeftData('BRENTUSD', 'BRENT');
        updateNeftData('WTIUSD', 'WTI');

        setIsLoaded(true);
        // Устанавливаем флаг isLoaded в true после всех запросов
        setTimeout(() => {
            requireDataResources();
        }, 2000);
    };

    useEffect(() => {
        requireData();
        requireDataBtc();
        requireDataBnb();
        requireDataStocks();
        requireDataResources();
    }, []);
    (function() {
        function decimalAdjust(type, value, exp) {
            if (typeof exp === 'undefined' || +exp === 0) {
                return Math[type](value);
            }
            value = +value;
            exp = +exp;
            if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
                return NaN;
            }
            value = value.toString().split('e');
            value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
            value = value.toString().split('e');
            return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
        }

        if (!Math.floor10) {
            Math.floor10 = function(value, exp) {
                return decimalAdjust('floor', value, exp);
            };
        }
    })();

    return (
        <>

            <div className="bin__w-left">
                <div className="bin__l-top">
                    <div className="bin__search">
                        <button className="button">
                            <img src={srch} alt="" />
                        </button>
                        <input type="text" className="input ipg" placeholder="Search" onChange={event => setQuery(event.target.value)}/>
                    </div>
                    <div className="bin__slider-box">
                        <div className="bin__line">
                            <a href="">
                                <img src={zvezda} alt="" />
                            </a>
                        </div>
                        <div className="bin__slider">
                            <div className="bin__swiper">
                                <div className="bin__slide">
                                    <a className={`pointer ${ activeCoin === "usdt" ? "oranj" : ""}`} onClick={() => sac("usdt")}>
                                        USDT
                                    </a>
                                </div>
                                <div className="bin__slide">
                                    <a className={`pointer ${ activeCoin === "btc" ? "oranj" : ""}`} onClick={() => sac("btc")}>BTC</a>
                                </div>
                                <div className="bin__slide">
                                    <a className={`pointer ${ activeCoin === "bnb" ? "oranj" : ""}`} onClick={() => sac("bnb")}>BNB</a>
                                </div>
                                <div className="bin__slide ttnone">
                                    <a className={`pointer ${ activeCoin === "stocks" ? "oranj" : ""}`} onClick={() => sac("stocks")}>Акции</a>
                                </div>
                                <div className="bin__slide ttnone">
                                    <a className={`pointer ${ activeCoin === "resources" ? "oranj" : ""}`} onClick={() => sac("resources")}>Металлы</a>
                                </div>
                                <div className="bin__slide ttnone">
                                    <a className={`pointer ${ activeCoin === "neft" ? "oranj" : ""}`} onClick={() => sac("neft")}>Нефть</a>
                                </div>
                            </div>
                        </div>
                        <div className="bin__line">
                            <img src="img/right.svg?_v=1689845484602" alt="" />
                        </div>
                    </div>
                </div>
                <div className="bin__cotirovki">
                    <div className="bin__c-top">
                        <div className="bin__c-top-item">
                            <span>Pair</span>
                            <span>
                        <img src="img/cotir.svg?_v=1689845484602" alt="" />
                      </span>
                        </div>
                        <div className="bin__c-top-item">
                            <span>Price</span>
                            <span>
                        <img src="img/cotir.svg?_v=1689845484602" alt="" />
                      </span>
                        </div>
                        <div className="bin__c-top-item">
                            <span>Change</span>
                            <span>
                        <img src="img/cotir.svg?_v=1689845484602" alt="" />
                      </span>
                            <span>
                        <img src="img/tuda.svg?_v=1689845484602" alt="" />
                      </span>
                        </div>
                    </div>
                    <div className="bin__c-body scrolling-body">

                        {isLoaded
                            ?

                             (activeCoin === "usdt" ?
                                pairsUsdt.filter(post => {
                                    if (query === '') {
                                        return post;
                                    } else if (post.symbol.toLowerCase().includes(query.toLowerCase())) {
                                        return post;
                                    }
                                }).map(symbols => <USDTtr lastPrice={symbols.lastPrice}
                                                          symbol={symbols.symbol}
                                                          priceChangePercent={Math.floor10(symbols.priceChangePercent, -2)}/>)
                                : "")

                            :
                            <>
                                <Loading/>
                                <Loading/>
                                <Loading/>
                            </>}
                        {isLoaded
                            ?

                            (activeCoin === "btc" ?
                                pairsBtc.filter(post => {
                                    if (query === '') {
                                        return post;
                                    } else if (post.symbol.toLowerCase().includes(query.toLowerCase())) {
                                        return post;
                                    }
                                }).map(symbols => <USDTtr lastPrice={symbols.lastPrice}
                                                          symbol={symbols.symbol}
                                                          priceChangePercent={Math.floor10(symbols.priceChangePercent, -2)}/>)
                                : "")

                            :
                            <>
                                <Loading/>
                                <Loading/>
                                <Loading/>
                            </>}
                        {isLoaded
                            ?

                            (activeCoin === "bnb" ?
                                pairsBnb.filter(post => {
                                    if (query === '') {
                                        return post;
                                    } else if (post.symbol.toLowerCase().includes(query.toLowerCase())) {
                                        return post;
                                    }
                                }).map(symbols => <USDTtr lastPrice={symbols.lastPrice}
                                                          symbol={symbols.symbol}
                                                          priceChangePercent={Math.floor10(symbols.priceChangePercent, -2)}/>)
                                : "")

                            :
                            <>
                                <Loading/>
                                <Loading/>
                                <Loading/>
                            </>}
                        {isLoaded
                            ?
                            (activeCoin === "stocks" ?
                                pairsStocks.filter(post => {
                                    if (query === '') {
                                        return post;
                                    } else if (post.symbol.toLowerCase().includes(query.toLowerCase())) {
                                        return post;
                                    }
                                }).map(symbols => <USDTtr
                                    stockName={symbols.symbol}
                                    stock={true}
                                    lastPrice={symbols.lastPrice}
                                    symbol={symbols.symbol}
                                    // frame={symbols.frame}
                                    priceChangePercent={Math.floor10(symbols.priceChangePercent, -2)}/>)

                                : "")

                            :
                            <>
                                <Loading/>
                                <Loading/>
                                <Loading/>
                            </>}
                        {isLoaded
                            ?
                            (activeCoin === "resources" ?
                                pairsResources.filter(post => {
                                    if (query === '') {
                                        return post;
                                    } else if (post.symbol.toLowerCase().includes(query.toLowerCase())) {
                                        return post;
                                    }
                                }).map(symbols => <USDTtr
                                    stockName={symbols.symbol}
                                    stock={false}
                                    resource={true}
                                    lastPrice={symbols.lastPrice}
                                    symbol={symbols.symbol}
                                    priceChangePercent={Math.floor10(symbols.priceChangePercent, -2)}/>)

                                : "")

                            :
                            <>
                                <Loading/>
                                <Loading/>
                                <Loading/>
                            </>}
                        {isLoaded
                            ?
                            (activeCoin === "neft" ?
                                pairsNeft.filter(post => {
                                    if (query === '') {
                                        return post;
                                    } else if (post.symbol.toLowerCase().includes(query.toLowerCase())) {
                                        return post;
                                    }
                                }).map(symbols => <USDTtr
                                    stockName={symbols.symbol}
                                    stock={false}
                                    resource={true}
                                    lastPrice={symbols.lastPrice}
                                    symbol={symbols.symbol}
                                    priceChangePercent={Math.floor10(symbols.priceChangePercent, -2)}/>)

                                : "")

                            :
                            <>
                                <Loading/>
                                <Loading/>
                                <Loading/>
                            </>}


                    </div>
                </div>
            </div>



        </>
    );
};

export default PairsList;
