import React from 'react';
import './comps.css'
import Bar from "./bar";
import img1 from "../../images/specials1.png";
const Contacts = () => {
    return (
        <div className="foot_container">
            <div className="specials_1">
                <div>
                    <h1 className="specials_h1">Наши Контакты</h1>
                </div>
                <img src={img1} className="specials_img" alt=""/>
            </div>
        <div className="comps__body comps__contact">
            <Bar active={4}/>
            <div className="comps__content w-full">
                <p>
    <span style={{ color: "rgba(254, 119, 1, 1)" }}>
      <span style={{ fontSize: 20 }}>Контакты</span>
    </span>
                </p>
                <p>
                    <br />
                    <span style={{ fontSize: 14 }}>
      Если у Вас возникли какие-либо вопросы, наши эксперты всегда готовы
      помочь! <br/>Свяжитесь с нами по электронной почте службы поддержки:{" "}
                        <span style={{ color: "rgba(254, 119, 1, 1)" }}><br />support@fenixodex.com</span><br /><span
                        style={{color: "rgba(254, 119, 1, 1)"}}>fenixodex.supp@gmail.com</span>
    </span><br/>
                    <span style={{color: "rgba(170, 170, 170, 1)"}}>ИНН: 6783452783</span>
                </p>
            </div>
        </div>
        </div>
    );
};

export default Contacts;