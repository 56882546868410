import React from 'react';
import "./chart/interval.css"

const ChartChangeInterval = () => {
    let selectedInterval = localStorage.getItem("interval")
    const handleChangeInterval = (itv) => {
        localStorage.setItem("interval", itv)
        document.location.reload()
    }
    return (
        <div className="intervals">
            <button className={`p-1 fb ${selectedInterval == "day" ? "activeInterval" : ""}`} onClick={() => handleChangeInterval("day")}>day</button>
            <button className={`p-1 fb ${selectedInterval == "month" ? "activeInterval" : ""}`} onClick={() => handleChangeInterval("month")}>month</button>
            <button className={`p-1 fb ${selectedInterval == "year" ? "activeInterval" : ""}`} onClick={() => handleChangeInterval("year")}>year</button>
        </div>
    );
};

export default ChartChangeInterval;