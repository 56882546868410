import React, {useEffect, useState} from 'react';
import q1 from "./img/q1.svg";
import reqwi from "./img/reqwi.png";
import q2 from "./img/q2.svg";
import './css/dpwd.css';
import axios from "axios";
import {closeSnackbar, enqueueSnackbar} from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from '@mui/material/CircularProgress';
import { yellow } from '@mui/material/colors';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useNavigate} from "react-router";

const Withdraw = () => {

    const action = snackbarId => (
        <button onClick={() => { closeSnackbar(snackbarId) }}>
            <CloseIcon/>
        </button>
    );
    const [showBankTransfer, setShowBankTransfer] = useState(true);
    const [showCryptoPayment, setShowCryptoPayment] = useState(true);

    const [hdnpp, setHdnpp] = useState(true)


    const [bankTransferAmount, setBankTransferAmount] = useState('');

    const [bankTransferVar, setBankTransferVar] = useState('');
    const [bankTransferDep, setBankTransferDep] = useState('');

    const [cryptoTransferVar, setCryptoTransferVar] = useState('');
    const [cryptoTransferDep, setCryptoTransferDep] = useState('');
    const [cryptoVar, setCryptoVar] = useState('');


    const [qiwiAmount, setQiwiAmount] = useState('');
    const [mirCardAmount, setMirCardAmount] = useState('');

    const [btcAmount, setBtcAmount] = useState('');
    const [ethAmount, setEthAmount] = useState('');
    const [usdtAmount, setUsdtAmount] = useState('');


    const [cardNumber, setCardNumber] = useState('');
    const [cryptoWallet, setCryptoWallet] = useState('');
    const [ownerName, setOwnerName] = useState('');
    const [sbpp, setsbpp] = useState('');
    const [bankName, setBankName] = useState('');
    const [buttonCrypto, setbuttonCrypto] = useState(true);
    const [buttonBank, setbuttonBank] = useState(true);
    const cryptoHandle = event => {
        setCryptoWallet(event)
        if(event == ""){
            setbuttonCrypto(true)
        }
        else{
            setbuttonCrypto(false)
        }
    }
    const bankONHandle = event => {
        setOwnerName(event)
        if(event == "" || bankName == "" || sbpp == "" || cardNumber == ""){
            setbuttonBank(true)
        }
        else{
            setbuttonBank(false)
        }
    }
    const bankBNHandle = event => {
        setBankName(event)
        if(event == "" || ownerName == "" || sbpp == "" || cardNumber == ""){
            setbuttonBank(true)
        }
        else{
            setbuttonBank(false)
        }
    }
    const bankSBPHandle = event => {
        setsbpp(event)
        if(event == "" || bankName == "" || ownerName == "" || cardNumber == ""){
            setbuttonBank(true)
        }
        else{
            setbuttonBank(false)
        }
    }
    const bankCNHandle = event => {
        setCardNumber(event)
        if(event == "" || bankName == "" || sbpp == "" || ownerName == ""){
            setbuttonBank(true)
        }
        else{
            setbuttonBank(false)
        }
    }
    const handleBankTransferClick = () => {
        setShowBankTransfer(!showBankTransfer);
    };


    const [portfolioActive, setPortfolioActive] = useState()
    const [ILPA, setILPA] = useState(false)
    const getUserPortfolioData = () => {
        axios.get("https://api.fenixodex.com/api/api/user/portfolio", {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setPortfolioActive(res.data.withdrawDeny)
                setILPA(true)
            })
    }

    const handleCryptoPaymentClick = () => {
        setShowCryptoPayment(!showCryptoPayment);
    };


    const inputSwitcher = (btv) => {
        switch (btv) {
            case "Visa / MasterCard":
                setBankTransferDep(bankTransferAmount)
                break
            case "QIWI":
                setBankTransferDep(qiwiAmount)
                break
            case "MIR":
                setBankTransferDep(mirCardAmount)
                break
        }
    }
    const inputSwitcherCrypto = (btv) => {
        switch (btv) {
            case "BTC":
                setCryptoTransferDep(btcAmount)
                break
            case "ETH":
                setCryptoTransferDep(ethAmount)
                break
            case "USDT":
                setCryptoTransferDep(usdtAmount)
                break
        }
    }

    useEffect(() => {
        inputSwitcher(bankTransferVar)
    }, )

    useEffect(() => {
        inputSwitcherCrypto(cryptoTransferVar)
    }, )

    const token = localStorage.getItem('token');
    const [idbk, setIdbk] = useState();
    const [idcr, setIdcr] = useState();
    const [cursCb, setCursCb] = useState(90);
    const cbc = () => {
        axios.get(`https://www.cbr-xml-daily.ru/daily_json.js`)
            .then(res => {

                setCursCb(res.data.Valute.USD.Value)
            })
    }
    useEffect(() => {
        cbc()
        getUserPortfolioData()
    }, [])
    let navigate = useNavigate();
    const handleBankStatus = () => {
        axios.get(`https://api.fenixodex.com/api/api/withdraw/bank/status?id=${idbk}`)
            .then(res => {
                if (res.data == "accepted"){
                    navigate(`../account/`, { replace: true });
                }
                else if (res.data == "waiting"){
                    setTimeout(() => handleBankStatus(), 2000)
                }
            })
    }

    const handleCryptoStatus = () => {
        axios.get(`https://api.fenixodex.com/api/api/withdraw/bank/status?id=${idcr}`)
            .then(res => {
                if (res.data == "accepted"){
                    navigate(`../account/`, { replace: true });
                }
                else if (res.data == "waiting"){
                    setTimeout(() => handleCryptoStatus(), 2000)
                }
            })
    }
    useEffect(() => {
        handleBankStatus()
    }, [idbk])
    useEffect(() => {
        handleCryptoStatus()
    }, [idcr])

    const handleBankPayment = () => {
        axios.post(`https://api.fenixodex.com/api/api/users/balance/withdraw/bank`,
            {
                "value": bankTransferDep,
                "rubles_value": Math.floor10( bankTransferDep * (cursCb * 0.98)),
                "card_type": bankTransferVar,
                "owner_name": ownerName,
                "owner_details": cardNumber,
                "coin": "USDT",
                "coin_from": "USDT",
                "balance_type": "main",
                "phone_number": sbpp,
                "bank_name": bankName
            },
            {
                headers: {"Authorization" : `Bearer ${token}`}
            })
            .then(res => {
                enqueueSnackbar(`Успешно!`, {action, 'variant': 'success', 'className': "snacksuccess"});
                setHdnpp(false)
                setIdbk(res.data)
            })
            .catch( error => {
                error.response.data.detail.map(
                    errormsg => {
                        enqueueSnackbar(`Возникла ошибка: ${errormsg.msg}`, {action, 'variant': 'error', 'className': "snackerror"});
                    }
                )


            });
    }

    const handleCryptoPayment = () => {
        axios.post(`https://api.fenixodex.com/api/api/users/balance/withdraw/crypto`,{
                "balance_type": "main",
                "value": cryptoTransferDep,
                "coin_to": cryptoTransferVar,
                "coin_from": cryptoTransferVar,
                "owner_details": cryptoWallet
            },
            {
                headers: {"Authorization" : `Bearer ${token}`}
            })
            .then(res => {
                enqueueSnackbar(`Успешно!`, {action, 'variant': 'success', 'className': "snacksuccess"});
                setHdnpp(false)
                setIdcr(res.data)
            })
            .catch( error => {
                error.response.data.detail.map(
                    errormsg => {
                        enqueueSnackbar(`Возникла ошибка: ${errormsg.msg}`, {action, 'variant': 'error', 'className': "snackerror"});
                    }
                )


            });
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: yellow[600],
            },
        },
    });
    return (
        <div className="tabs__body ">

            <div className={`deposit-popup-window ${hdnpp ? "hidden" : ""}`} onClick={() => setHdnpp(true)}>
                <div className="deposit-popup" >
                    <p className="dp-pp-text">Ожидание платежа</p>
                    <ThemeProvider theme={theme}>
                        <CircularProgress />
                    </ThemeProvider>

                </div>

            </div>

            <div className="account__box">


                    { portfolioActive && ILPA ?
                        <div className="deposits__body">
                            <div className="deps__rejected-text">
                                в данный момент у вас есть активный портфель. вывод средств невозможен.
                            </div>
                        </div>

                     :
                        <div className="deposits__body">
                        <div className="account__balans">Способы вывода средств</div>
                        <div className="deposits__wrap">
                        <div className="deposits__bar">
                        <div className={`deposits__q1`} onClick={handleBankTransferClick}>
                        <img src={q1} alt="" />
                        <span>Банковский перевод</span>
                        </div>
                        <div className={`dep2  ${showBankTransfer ? "hidden" : ""}`}>
                                <div className="deposits__oplata">
                                    <div className="deposits__oplata-line">
                                        <input
                                            id="c_12"
                                            data-error="Ошибка"
                                            className="check-with-label checkbox__input"
                                            type="radio"
                                            defaultValue={1}
                                            onClick={() => setBankTransferVar("Visa / MasterCard")}
                                            name="form[]"
                                        />
                                        <label
                                            htmlFor="c_12"
                                            className="checkbox__label label-for-check"
                                        >
                                            <span className="checkbox__text">Visa / Mastercard</span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`deposits__summa ${bankTransferVar == "Visa / MasterCard" ? "" : "hidden"}`}
                                            placeholder="Сумма вывода в $"
                                            onKeyPress={(event) => {
                                                if (!/[0-9.]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={bankTransferAmount}
                                            onChange={(e) => setBankTransferAmount(e.target.value)}
                                        />
                                    </div>
                                    <div className="deposits__oplata-line">
                                        <input
                                            id="c_123"
                                            data-error="Ошибка"
                                            className="check-with-label checkbox__input"
                                            type="radio"
                                            defaultValue={1}
                                            onClick={() => setBankTransferVar("MIR")}
                                            name="form[]"
                                        />
                                        <label
                                            htmlFor="c_123"
                                            className="checkbox__label label-for-check"
                                        >
                                            <span className="checkbox__text">Карта МИР</span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`deposits__summa ${bankTransferVar == "MIR" ? "" : "hidden"}`}
                                            placeholder="Сумма вывода в $"
                                            onKeyPress={(event) => {
                                                if (!/[0-9.]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={mirCardAmount}
                                            onChange={(e) => setMirCardAmount(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="deposits__reqwi">
                                    <div className="deposits__reqwi-title">
                                        Реквизиты для вывода
                                    </div>
                                    <input
                                        type="text"
                                        placeholder="Имя владельца карты"
                                        className="perevod"
                                        value={ownerName}
                                        onChange={(e) => bankONHandle(e.target.value)}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Название банка"
                                        className="perevod"
                                        value={bankName}
                                        onChange={(e) => bankBNHandle(e.target.value)}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Номер телефона СБП"
                                        className="perevod"
                                        value={sbpp}
                                        onChange={(e) => bankSBPHandle(e.target.value)}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Номер карты"
                                        className="perevod"
                                        value={cardNumber}
                                        onChange={(e) => bankCNHandle(e.target.value)}
                                    />
                                    <div className="deposits__text">
                                        Будьте внимательны при вводе реквизитов.Отправленные
                                        средства на другие реквизиты возврату не подлежат.
                                    </div>
                                    <div className="deposits__lion">
                                        Cумма к выводу: { Math.floor10( bankTransferDep * (cursCb * 0.98))} RUB
                                    </div>
                                </div>
                                <button disabled={buttonBank} className="deposits__btn" onClick={handleBankPayment}>Отправить заявку</button>
                            </div>
                        </div>
                        <div className="deposits__bar">
                <div className="deposits__q1" onClick={handleCryptoPaymentClick}>
                    <img src={q2} alt="" />
                    <span>Оплата криптовалютой</span>
                </div>
                <div className={`dep2  ${ showCryptoPayment ? "hidden" : ""}`}>
                    <div className="deposits__oplata">
                        <div className="deposits__oplata-line">
                            <input
                                id="c_124"
                                data-error="Ошибка"
                                className="check-with-label checkbox__input"
                                type="radio"
                                defaultValue={1}
                                onClick={() => setCryptoTransferVar("BTC")}
                                name="form[]"
                            />
                            <label
                                htmlFor="c_124"
                                className="checkbox__label label-for-check"
                            >
                                <span className="checkbox__text">BTC</span>
                            </label>
                            <input
                                type="text"
                                className={`deposits__summa ${cryptoTransferVar == "BTC" ? "" : "hidden"}`}
                                placeholder="Сумма вывода"
                                            onKeyPress={(event) => {
                                                if (!/[0-9.]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                value={btcAmount}
                                onChange={(e) => setBtcAmount(e.target.value)}
                            />
                        </div>
                        <div className="deposits__oplata-line">
                            <input
                                id="c_125"
                                data-error="Ошибка"
                                className="check-with-label checkbox__input"
                                type="radio"
                                defaultValue={1}
                                onClick={() => setCryptoTransferVar("ETH")}
                                name="form[]"
                            />
                            <label
                                htmlFor="c_125"
                                className="checkbox__label label-for-check"
                            >
                                <span className="checkbox__text">Ethereum ERC20</span>
                            </label>
                            <input
                                type="text"
                                className={`deposits__summa ${cryptoTransferVar == "ETH" ? "" : "hidden"}`}
                                placeholder="Сумма вывода"
                                            onKeyPress={(event) => {
                                                if (!/[0-9.]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                value={ethAmount}
                                onChange={(e) => setEthAmount(e.target.value)}
                            />
                        </div>
                        <div className="deposits__oplata-line">
                            <input
                                id="c_126"
                                data-error="Ошибка"
                                className="check-with-label checkbox__input"
                                type="radio"
                                defaultValue={1}
                                onClick={() => setCryptoTransferVar("USDT")}
                                name="form[]"
                            />
                            <label
                                htmlFor="c_126"
                                className="checkbox__label label-for-check"
                            >
                                <span className="checkbox__text">USDT TRC20</span>
                            </label>
                            <input
                                type="text"
                                className={`deposits__summa ${cryptoTransferVar == "USDT" ? "" : "hidden"}`}
                                placeholder="Сумма вывода"
                                            onKeyPress={(event) => {
                                                if (!/[0-9.]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                value={usdtAmount}
                                onChange={(e) => setUsdtAmount(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="deposits__reqwi">
                        <div className="deposits__reqwi-title">
                            Адрес Вашего кошелька
                        </div>
                        <input
                            type="text"
                            placeholder={`Ваш кошелёк ${cryptoTransferVar}`}
                            className="perevod steps"

                            value={cryptoWallet}
                            onChange={event => cryptoHandle(event.target.value)}
                        />
                        <div className="deposits__text">
                            Будьте внимательны при вводе реквизитов.Отправленные
                            средства на другие реквизиты возврату не подлежат.
                        </div>
                        <div className="deposits__lion">
                            Cумма к выводу:  {cryptoTransferDep} {cryptoTransferVar}
                        </div>
                    </div>
                    <button disabled={buttonCrypto} className="deposits__btn" onClick={handleCryptoPayment}>Отправить заявку</button>
                </div>
            </div>
                        </div>
                        </div>
                    }


            </div>
        </div>

    );
};

export default Withdraw;