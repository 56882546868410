import React from 'react';
import './comps.css'
import Bar from "./bar";
import img1 from "../../images/specials1.png";
const About = () => {
    return (
        <div className="foot_container">
            <div className="specials_1">
                <div>
                    <h1 className="specials_h1">О нас</h1>
                    <h2 className="specials_h2">Узнай больше о FenixoDEX</h2>
                </div>
                <img src={img1} className="specials_img" alt=""/>
            </div>
            <div className="comps__body">
            <Bar active={1}/>
            <div className="comps__content">

                <p>
                    <span style={{ fontSize: 20, color: "rgba(254, 119, 1, 1)" }}>О нас</span>
                </p>
                <p><strong>FenixoDEX</strong> &mdash; это глобальная криптовалютная биржа, предоставляющая платформу для торговли различными криптовалютами. Она была основана в 2021 году и зарегистрирована на Сейшельских островах. Группа работает в России, Бразилии, Вьетнаме, Сейшельских островах, Сингапуре, Японии и Индонезии, занимаясь исследованиями и разработками в области маркетинга, инвестиций и технологий блокчейна.</p>
                <p>&nbsp;</p>
                <p>Миссия <strong>FenixoDEX</strong> &mdash; предоставить всем гражданам в эпоху цифровых технологий наиболее безопасные и удобные услуги торговли и опеки. Из-за недавних лазеек в рыночной безопасности и нестабильности политики мы считаем, что граждане в эпоху цифровых технологий заслуживают лучшего выбора, поскольку им нужна действительно безопасная, удобная и заслуживающая доверия инфраструктура. <strong>FenixoDEX</strong> помогает пользователям находить более ценные зашифрованные активы, обеспечивать высококачественный торговый опыт и гарантировать, что клиенты могут безопасно и удобно торговать и управлять своими цифровыми активами с помощью наших современных технологий и процессов управления.</p>
                <p>&nbsp;</p>
                <p>Что отличает <strong>FenixoDEX</strong>?</p>
                <p>1. Чрезвычайно безопасный</p>
                <p><strong>FenixoDEX</strong> использует двухэтапную проверку и уведомление;</p>
                <p><strong>FenixoDEX</strong> разделяет холодные и горячие кошельки, развертывает несколько подписей и отслеживает состояние кошельков и активов в режиме реального времени. Никто не может получить закрытый ключ самостоятельно из-за способа создания зашифрованного ключа.</p>
                <p><strong>FenixoDEX</strong> стремится стать самой безопасной в мире платформой для торговли активами блокчейна, используя глубоко укоренившийся технологический опыт и операционный процесс, значительно снижая риски и гарантируя безопасность цифровых активов наших пользователей.</p>
                <p>&nbsp;</p>
                <p>2. Универсальное обслуживание</p>
                <p>В настоящее время <strong>FenixoDEX</strong> имеет полную линейку продуктов, таких как спотовые пулы, кредитное плечо, фьючерсы, управление активами. В рамках удовлетворения разнообразных потребностей пользователей в инвестиционных активах <strong>FenixoDEX</strong> внимательно отслеживает движение рынка, что помогает пользователям участвовать в тенденциях, что поможет им обеспечить свои преимущества.</p>
                <p>&nbsp;</p>
                <p>3. Доверительное управление несколькими активами</p>
                <p><strong>FenixoDEX</strong> поддерживает несколько активов блокчейна, включая BTC, ETH, ERC20, ETC, ZEC, SC. <strong>FenixoDEX</strong> стремится удовлетворить все требования пользователей.</p>
                <p>&nbsp;</p>
                <p>4. Бесперебойный торговый опыт</p>
                <p><strong>FenixoDEX</strong> использует передовые технологии для обеспечения скорости транзакций, обновляет подробную книгу заказов и прибыль/убыток, чтобы пользователи могли получить лучший торговый опыт, который у них когда-либо был.</p>
                <p>&nbsp;</p>
                <p>5. Поддержка нескольких платформ</p>
                <p><strong>FenixoDEX</strong> &mdash; это веб-платформа, поддерживающая все операционные системы и устройства.</p>
                <p>&nbsp;</p>
                <p>6. Подтверждение резервов (100% резервов активов)</p>
                <p><strong>FenixoDEX</strong> хранит активы пользователей в резервах и не будет использовать эти активы каким-либо образом. Механизм, предоставляемый <strong>FenixoDEX</strong>, позволяет пользователям отслеживать состояние активов в любое время.</p>
            </div>
        </div>
        </div>
    );
};

export default About;