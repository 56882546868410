import React, {useEffect, useState} from 'react';
import {BrowserRouter, NavLink} from "react-router-dom";
import './logout.sass';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import axios from "axios";
import money from './verstka(temp)/img/money.svg'
import logo from '../images/FenixoDEX logo.svg'
import LogoutIcon from '@mui/icons-material/Logout';
import {grey} from "@mui/material/colors";
const Header = (props) => {



    const [marketInfo, setMarketInfo] = useState([]);
    const url1 = new URL(document.location.href);
    let symbolURL1 = (url1.searchParams.get('symbol'));
    let symbolDivided = ""
    const PairMatch = () => {
        let symbolLength = `${symbolURL1}`.length;
        if (symbolLength === 7) {
            symbolDivided = symbolURL1[0] + symbolURL1[1] + symbolURL1[2] + '/USDT'}

        else {
                symbolDivided = symbolURL1[0] + symbolURL1[1] + symbolURL1[2] + symbolURL1[3] + '/USDT';
            }
        }
    if (!symbolURL1) {
    }
    else{
        PairMatch()
    }
    const requireDataMarket = () => axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbol=${symbolURL1}`)
        .then(res => {
            setMarketInfo(res.data);
            setTimeout(() => requireDataMarket(), 2000)
        })

    useEffect(() => {
        requireDataMarket();
    }, []);
    let priceChangePercent = marketInfo.priceChangePercent;
    function colorChange(priceChangePercent) {
        if (priceChangePercent > 0) {
            return "success-text";
        }

        else {
            return "text-danger";
        }
    }
    const urlId = localStorage.getItem('userLogin');
    const isLoggedIn = localStorage.getItem('isLoggedIn')
    const [UserName, setUserName] = useState(localStorage.getItem('userName'))
    const [UserSurname, setUserSurname] = useState(localStorage.getItem('userSurname'))
    const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail'))

    useEffect(() =>{
        setTimeout(setUserName (localStorage.getItem('userName')), 1000)
        setTimeout(setUserSurname(localStorage.getItem('userSurname')), 1000)
        setTimeout(setUserEmail(localStorage.getItem('userEmail')), 1000)
    },[isLoggedIn])
    return (<>

        <header className="header">
            <div className="header__container">
                <div className="header__top">
                    <NavLink to="/main"><a href="" className="header__logo">
                        <img src={logo} alt="" />
                    </a></NavLink>
                    <div className="header__top-right">
                        { isLoggedIn ? (<>
                                    <NavLink to="/account/deposit">
                                        <a href="" className="header__money">
                                            <img src={money} alt="" />
                                            <span>Пополнение</span>
                                        </a>
                                    </NavLink>

                            <div className="header__btn">
                                <NavLink to="/market?symbol=BTCUSDT">
                                <div className="header__btn-hover">
                                    <span>Маркет</span>
                                </div>
                                </NavLink>
                            </div>

                        <div className="header__btn">
                            <NavLink to="/account/wallet">
                            <div className="header__btn-hover">
                                <span>Конвертация</span>
                            </div>
                            </NavLink>
                        </div>

                        <div className="header__btn">
                            <NavLink to="/account">
                            <div className="header__btn-hover">
                                <span>Кошелек</span>
                            </div>
                            </NavLink>
                        </div>

                        <div className="header__btn">
                            <NavLink to="/account/orders">
                            <div className="header__btn-hover">
                                <span>Ордера</span>
                            </div>
                            </NavLink>
                        </div>

                        <div className="header__indi-box">
                            <div className="header__indi">
                                <NavLink to="/account">
                                    <AccountCircleOutlinedIcon sx={{ color: grey[50], width: 25, height: 25 }}/>
                                </NavLink>
                            </div>
                                <div className="header__indi" onClick={props.logOut}>
                                    <a href="">
                                        <LogoutIcon sx={{ color: grey[50], width: 25, height: 25  }}/>
                                    </a>
                                </div>



                        </div>

                    </>
                    )
                    :( <>
                    <NavLink to="/authorization" className="log-btn" >
                        войти
                    </NavLink>


                    <NavLink to="/register" className="reg-btn" >
                        регистрация
                    </NavLink>
                </>
                    )}
                    </div>
                </div>
            </div>
        </header>

        </>
    );
};

export default Header;
