import React from 'react';
import {Skeleton} from "@mui/material";

const Loading = () => {
    return (
        <>
            <Skeleton/>
            <Skeleton/>
            <Skeleton/>
            <Skeleton/>
            <Skeleton/>
            <Skeleton/>
            <Skeleton/>
            <Skeleton/>
            <Skeleton/>
            <Skeleton/>
            <Skeleton/>
            <Skeleton/>
        </>
    );
};

export default Loading;
