import React, {useEffect, useState} from 'react';
import './landing.css'
import {NavLink} from "react-router-dom";
import pt from '../images/1.png'
import gkp from '../images/2.png'
import ss from '../images/3.png'
import vub from '../images/4.png'
import usur from '../images/5.png'
import sp from '../images/6.png'
import imgw2 from '../images/landing/w2.png'
import imgw1 from '../images/landing/w1.png'
import imgw3 from '../images/landing/w3.png'
import img4 from '../images/landing/4 img.png'
import img5 from '../images/landing/5 img.png'
import img6 from '../images/landing/6 img.png'
import img7 from '../images/landing/7 img.png'
import img8 from '../images/landing/8 img.png'
import img9 from '../images/landing/9 img.png'
import img10 from '../images/landing/10 img.png'
import img11 from '../images/landing/11 img.png'
import img12 from '../images/landing/12 img.png'
import img13 from '../images/landing/13 img.png'
import img14 from '../images/landing/14 img.png'
import img15 from '../images/landing/15 img.png'
import img16 from '../images/landing/16 img.png'
import img17 from '../images/landing/17 img.png'
import img18 from '../images/landing/18 img.png'
import img19 from '../images/landing/19 img.png'
import img20 from '../images/landing/20 img.png'
import img21 from '../images/landing/21 img.png'
import img22 from '../images/landing/22 img.png'
import img23 from '../images/landing/23 img.png'
import img24 from '../images/landing/24 img.png'
import img25 from '../images/landing/25 img.png'
import img26 from '../images/landing/26 img.png'
import img27 from '../images/landing/27 img.png'
import img28 from '../images/landing/28 img.png'
import img29 from '../images/landing/29 img.png'
import img30 from '../images/landing/30 img.png'
import img31 from '../images/landing/31 img.png'
import img32 from '../images/landing/32 img.png'
import img33 from '../images/landing/33 img.png'
import img34 from '../images/landing/34 img.png'
import img35 from '../images/landing/35 img.png'
import img36 from '../images/landing/36 img.png'
import img37 from '../images/landing/37 img.png'
import img38 from '../images/landing/38 img.png'
import img39 from '../images/landing/39 img.png'
import img40 from '../images/landing/40 img.png'
import img41 from '../images/landing/41 img.png'
import img42 from '../images/landing/42 img.png'
import img43 from '../images/landing/43 img.png'
import img44 from '../images/landing/img 44.png'
import img45 from '../images/landing/img 45.png'
import phone from '../images/phone.png'
import axios from "axios";
import fdexvid from '../images/fenixodex3.mp4'

const Landing = () => {
    const [emailFromLanding, setEmailFromLanding] = useState("");
    const [emailFromLanding2, setEmailFromLanding2] = useState("");
    const emailFromLandingHandler = e => {
        setEmailFromLanding(e.target.value)
    }
    const emailFromLanding2Handler = e => {
        setEmailFromLanding2(e.target.value)
    }
    (function() {
        function decimalAdjust(type, value, exp) {
            if (typeof exp === 'undefined' || +exp === 0) {
                return Math[type](value);
            }
            value = +value;
            exp = +exp;
            if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
                return NaN;
            }
            value = value.toString().split('e');
            value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
            value = value.toString().split('e');
            return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
        }

        if (!Math.floor10) {
            Math.floor10 = function(value, exp) {
                return decimalAdjust('floor', value, exp);
            };
        }
    })();

    const [pair1, setPair1] = useState()
    const [pair2, setPair2] = useState()
    const [pair3, setPair3] = useState()
    const [pair4, setPair4] = useState()
    const [pair5, setPair5] = useState()
    const [isLoaded, setIsLoaded] = useState(false)
    const requireData = () => {
        axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbol=BTCUSDT`)
            .then(res => {
                setPair1(res.data);
                axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbol=ETHUSDT`)
                    .then(res => {
                        setPair2(res.data);
                        axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbol=ADAUSDT`)
                            .then(res => {
                                setPair3(res.data);
                                axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbol=DASHUSDT`)
                                    .then(res => {
                                        setPair4(res.data);
                                        axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbol=DOTUSDT`)
                                            .then(res => {
                                                setPair5(res.data);
                                                setIsLoaded(true)
                                            })
                                    })

                            })

                    })

            })

    }

    const [selectedItem, setSelectedItem] = useState("1");

    useEffect(() => {
        requireData()
    }, [])
    const isLoggedIn = localStorage.getItem("isLoggedIn")

    function handleClickSelect(s) {
        const selectedElem = document.getElementById(`sel${s}`)
        const sel1 = document.getElementById(`sel1`)
        const sel2 = document.getElementById(`sel2`)
        const sel3 = document.getElementById(`sel3`)
        sel1.classList.remove("l-select-item-selected")
        sel2.classList.remove("l-select-item-selected")
        sel3.classList.remove("l-select-item-selected")
        selectedElem.classList.add("l-select-item-selected")
        setSelectedItem(s)
    }

    return (
            <div className="l-body">
                <div className="l-block1">
                    <div className="l-block-1-1-1">
                    <div className="l-block1-1">
                        <h1 className="l-title">Начните свое путешествие в мире криптовалюты</h1>
                        <h1 className="l-title">Быстро. Эффективно. Безопасно.</h1>
                            { isLoggedIn ?
                                ""
                                :

                                <div className="land-start">
                                    <input type="text" className="land_sinput" value={emailFromLanding} onChange={emailFromLandingHandler} placeholder="Пожалуйста, введите Ваш E-mail"/>
                                    <NavLink to={`/register?emailFromLanding=${emailFromLanding}`} className="start-btn" >
                                        Начать торговлю
                                    </NavLink>
                                </div>
                            }
                    </div>
                    <img src={phone} className="phonem" alt=""/>
                    </div>
                </div>

                <div className="l-block6">
                    <h1 className="l-block3__title">Популярные криптовалюты</h1>
                    {/*<h1 className="l-block3__title mb16">самой низкой комиссией</h1>*/}
                    {isLoaded ? <div className="l-graph__container">

                            <div className="l-graph__item">
                                <div className="l-graph__title">Bitcoin</div>
                                <div className="l-graph__abr">BTC</div>
                                <div className="l-graph__coin">${Math.floor10( pair1.lastPrice, -1)}</div>
                                <div className={`l-graph__percent ${pair1.priceChangePercent > 0 ? "text-success" : "text-error"}`}>{Math.floor10(pair1.priceChangePercent, -2)}%</div>
                                <NavLink to="/market?symbol=ADAUSDT" className="l-graph__link">
                                    Торговать
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.9091 7L18 12M18 12L12.9091 17M18 12L6 12" className="l-graph-arrow" stroke="white" stroke-width="2" stroke-linecap="square"/>
                                    </svg>
                                </NavLink>
                            </div>

                            <div className="l-graph__item">
                                <div className="l-graph__title">Ethereum</div>
                                <div className="l-graph__abr">ETH</div>
                                <div className="l-graph__coin">${Math.floor10(pair2.lastPrice, -2)}</div>
                                <div className={`l-graph__percent ${pair2.priceChangePercent > 0 ? "text-success" : "text-error"}`}>{Math.floor10(pair2.priceChangePercent, -2)}%</div>
                                <NavLink to="/market?symbol=DASHUSDT" className="l-graph__link">
                                    Торговать
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.9091 7L18 12M18 12L12.9091 17M18 12L6 12" className="l-graph-arrow" stroke="white" stroke-width="2" stroke-linecap="square"/>
                                    </svg>
                                </NavLink>
                            </div>

                            <div className="l-graph__item">
                                <div className="l-graph__title">Cardano</div>
                                <div className="l-graph__abr">ADA</div>
                                <div className="l-graph__coin">${Math.floor10( pair3.lastPrice, -4)}</div>
                                <div className={`l-graph__percent ${pair3.priceChangePercent > 0 ? "text-success" : "text-error"}`}>{Math.floor10(pair3.priceChangePercent, -2)}%</div>
                                <NavLink to="/market?symbol=ADAUSDT" className="l-graph__link">
                                    Торговать
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.9091 7L18 12M18 12L12.9091 17M18 12L6 12" className="l-graph-arrow" stroke="white" stroke-width="2" stroke-linecap="square"/>
                                    </svg>
                                </NavLink>
                            </div>

                            <div className="l-graph__item">
                                <div className="l-graph__title">Dash</div>
                                <div className="l-graph__abr">DASH</div>
                                <div className="l-graph__coin">${Math.floor10(pair4.lastPrice, -3)}</div>
                                <div className={`l-graph__percent ${pair4.priceChangePercent > 0 ? "text-success" : "text-error"}`}>{Math.floor10(pair4.priceChangePercent, -2)}%</div>
                                <NavLink to="/market?symbol=DASHUSDT" className="l-graph__link">
                                    Торговать
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.9091 7L18 12M18 12L12.9091 17M18 12L6 12" className="l-graph-arrow" stroke="white" stroke-width="2" stroke-linecap="square"/>
                                    </svg>
                                </NavLink>
                            </div>

                            <div className="l-graph__item">
                                <div className="l-graph__title">Polkadot</div>
                                <div className="l-graph__abr">DOT</div>
                                <div className="l-graph__coin">${Math.floor10(pair5.lastPrice, -4)}</div>
                                <div className={`l-graph__percent ${pair5.priceChangePercent > 0 ? "text-success" : "text-error"}`}>{Math.floor10(pair5.priceChangePercent, -2)}%</div>
                                <NavLink to="/market?symbol=DOTUSDT" className="l-graph__link">
                                    Торговать
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.9091 7L18 12M18 12L12.9091 17M18 12L6 12" className="l-graph-arrow" stroke="white" stroke-width="2" stroke-linecap="square"/>
                                    </svg>
                                </NavLink>
                            </div>
                        </div>
                        : ""}
                </div>
                    <video controls className="l-block-vid" src={fdexvid}/>

                <h1 className="l-block3__title">Самая надежная криптовалютная биржа</h1>
                <h1 className="l-block3__subtitle mb16">Покупайте, торгуйте и храните более 150 криптовалют на Fenixo<span className="main-color">DEX</span></h1>
                <div className="l-block2">

                    <div className="l-block2__item">
                        <img src={pt} className="l-block2__image" alt=""/>
                        <div className="l-block2-text-container">
                            <h2 className="l-block2__title">Передовые технологии</h2>
                            <p className="l-block2__subtitle">Самостоятельно разработанная торговая система третьего поколения, обеспечивающая эффективную профессиональную торговлю</p>
                        </div>
                    </div>
                    <div className="l-block2__item">
                        <img src={gkp} className="l-block2__image" alt=""/>
                        <div className="l-block2-text-container">
                            <h2 className="l-block2__title">Глобальная команда профессионалов</h2>
                            <p className="l-block2__subtitle">Наша команда состоит из специалистов блокчейн-индустрий, вовлеченных в сферу цифровой валюты на протяжении многих лет</p>
                        </div>
                    </div>
                    <div className="l-block2__item">
                        <img src={ss} className="l-block2__image" alt=""/>
                        <div className="l-block2-text-container">
                            <h2 className="l-block2__title">Супер Спот</h2>
                            <p className="l-block2__subtitle">Новая функция, позволяющая Вам получать больше информации о покупках криптовалют, прибыли и убытках от продаж</p>
                        </div>
                    </div>
                    <div className="l-block2__item">
                        <img src={vub} className="l-block2__image" alt=""/>
                        <div className="l-block2-text-container">
                            <h2 className="l-block2__title">Высокий уровень безопасности</h2>
                            <p className="l-block2__subtitle">Структура горячего и холодного кошельков защищает Ваши активы изолированным методом и гарантирует им двойную безопасность</p>
                        </div>
                    </div>
                    <div className="l-block2__item">
                        <img src={usur} className="l-block2__image" alt=""/>
                        <div className="l-block2-text-container">
                            <h2 className="l-block2__title">Усиленная система управления рисками</h2>
                            <p className="l-block2__subtitle">Механизм защиты от ликвидации с двойной ценой для
                                защиты трейдеров с высоким кредитным плечом от
                                значительных убытков</p>
                        </div>
                    </div>
                    <div className="l-block2__item">
                        <img src={sp} className="l-block2__image" alt=""/>
                        <div className="l-block2-text-container">
                            <h2 className="l-block2__title">Служба поддержки 24/7</h2>
                            <p className="l-block2__subtitle">Отвечаем за считанные секунды и решаем любой ваш вопрос касаемо торговли на бирже</p>
                        </div>
                    </div>

                </div>

                <h1 className="l-block3__title">Уникальный торговый опыт</h1>
                <h1 className="l-block3__subtitle mb16">Инновационные инструменты для инвестирования в криптовалюту</h1>
                <div className="l-block-select">
                    <div className="l-select-bar">
                        <span onClick={() => handleClickSelect("1")} id="sel1" className="l-select-item l-select-item-selected">Скоростная Торговля K-Line</span>
                        <span onClick={() => handleClickSelect("2")} id="sel2" className="l-select-item">Режим разделения и объединения позиции</span>
                        <span onClick={() => handleClickSelect("3")} id="sel3" className="l-select-item">Обширные инструменты TP и SL</span>
                    </div>

                    { selectedItem == "1" ?
                        <div className="l-block-select-item">
                        <div className="l-block4-text-container">

                            <div className="d-flex">
                                <img src="https://www.deepcoin.com/static/images/kline-a-caa12835efa217d.svg" className="sel-icon" alt=""/>
                                <div className="l-block2-text-container">
                                    <h2 className="l-block2__title">Выставление ордера одним нажатием</h2>
                                    <p className="l-block2__subtitle">Скоростная торговля K-Line — это набор инновационных функциональных возможностей, эксклюзивно выпущенных только на FenixoDEX. Основываясь на мощной торговой функции K-Line, вы можете очень интуитивно и эффективно выполнять различные торговые операции с ордерами на K-Line графике.</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <img src="https://www.deepcoin.com/static/images/kline-b-fa69364c769ce19.svg" className="sel-icon" alt=""/>
                                <div className="l-block2-text-container">
                                    <h2 className="l-block2__title">Комплексное управление торговлей</h2>
                                    <p className="l-block2__subtitle">Благодря K-Line функции, больше не нужно переходить с одной страницы на другую для выставления ордера. При стремительном и неожиданном изменении рынка, вы можете быстро разместить (изменить) ордер или внести корректировки в любое время, прямо на графике!</p>
                                </div>
                            </div>
                        </div>
                            <img src={imgw3} className="img-w" alt=""/>
                        </div>
                        : "" }

                    { selectedItem == "2" ?
                        <div className="l-block-select-item">
                        <div className="l-block4-text-container">

                            <div className="d-flex">
                                <img src="https://www.deepcoin.com/static/images/fencang-a-881b204e6fd8356.svg" className="sel-icon" alt=""/>
                                <div className="l-block2-text-container">
                                    <h2 className="l-block2__title">Свободное изменение нескольких позиции одновременно</h2>
                                    <p className="l-block2__subtitle">Пользователи могут одновременно удерживать Лонг и Шорт позиции в одной и той же валюте, одновременно удерживая нескольких суб-позиций с разным уровнем левериджа, чтобы помочь трейдерам разработать гибкие торговые стратегии.</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <img src="https://www.deepcoin.com/static/images/fencang-b-072df1eba650cd6.svg" className="sel-icon" alt=""/>
                                <div className="l-block2-text-container">
                                    <h2 className="l-block2__title">Низкий риск для независимых позиции</h2>
                                    <p className="l-block2__subtitle">На нашей платформе, трейдеры могут держать несколько позиции в одной и той же валюте, с разным уровнем левериджа. В каждой суб-позиции можно независимо регулировать уровень левериджа, увеличивать или уменьшать позиции, устанавливать TP и SL, а также объединять суб-позиции без каких либо потерь.</p>
                                </div>
                            </div>
                        </div>
                            <img src={imgw2} className="img-w" alt=""/>
                        </div>
                        : "" }

                    { selectedItem == "3" ?
                        <div className="l-block-select-item">
                        <div className="l-block4-text-container">

                            <div className="d-flex">
                                <img src="https://www.deepcoin.com/static/images/zhiying-a-eba813ae24bba6c.svg" className="sel-icon" alt=""/>
                                <div className="l-block2-text-container">
                                    <h2 className="l-block2__title">Многоточечный функционал TP/SL</h2>
                                    <p className="l-block2__subtitle">Пользователи FenixoDEX могут воплощать свои различные торговые стратегии в любое время установливая TP и SL на уже выложенные ордера, для гибкого управления рисками, при этом поддерживается установка до 50 TP и SL на одну и ту же позицию, но с разными cont и ценой, включая лимитные и рыночные ордера.</p>
                                     </div>
                            </div>
                            <div className="d-flex">
                                <img src="https://www.deepcoin.com/static/images/zhiying-b-e88a7f7edc4a2ee.svg" className="sel-icon" alt=""/>
                                <div className="l-block2-text-container">
                                    <h2 className="l-block2__title">До 50 различных уровней TP/SL</h2>
                                    <p className="l-block2__subtitle">FenixoDEX обладает мощной функциональностью TP/SL, которая позволяет вам закрывать сделки в нескольких ценовых точках в рамках одной позиции. Если вы не уверены, в каком направлении пойдет рынок, вы можете контролировать риски, закрыв половину своей позиции с прибылью и оставив другую половину открытой. Защитите свои активы от колебаний рынка криптовалют и принудительной ликвидации с помощью этой замечательной функции.</p>
                                </div>
                            </div>
                        </div>
                            <img src={imgw1} className="img-w" alt=""/>
                        </div>
                        : "" }



                </div>


                <h1 className="l-block3__title">Освещение в СМИ</h1>

                <div className="l-grid-smi">
                    <div className="l-item-smi"><img src={img4} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img5} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img6} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img7} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img8} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img9} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img10} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img11} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img12} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img13} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img14} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img15} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img16} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img17} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img18} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img19} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img20} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img21} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img22} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img23} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img24} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img25} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img26} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img27} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img28} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img29} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img30} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img31} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img32} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img33} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img34} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img35} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img36} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img37} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img38} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img39} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img40} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img41} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img42} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img43} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img44} className="img-smi" alt=""/></div>
                    <div className="l-item-smi"><img src={img45} className="img-smi" alt=""/></div>
                </div>

                <div className="l-block-stat">
                    <div className="l-block-stat-2">
                    <div className="l-block-stat-item">
                        <h1 className="l-block-stat-title">5+</h1>
                        <p className="l-block-stat-description">Более пяти лет стабильной работы без сбоев и застреваний</p>
                    </div>
                    <div className="l-block-stat-item">
                        <h1 className="l-block-stat-title">2000+</h1>
                        <p className="l-block-stat-description">Публикации на известных платформах в СМИ</p>
                    </div>
                    <div className="l-block-stat-item">
                        <h1 className="l-block-stat-title">1000+</h1>
                        <p className="l-block-stat-description">KOL сотрудничают с нами</p>
                    </div>
                    <div className="l-block-stat-item">
                        <h1 className="l-block-stat-title">10M+</h1>
                        <p className="l-block-stat-description">Пользователей выбирают
                            нашу платформу</p>
                    </div>
                    </div>
                </div>

                <div className="l-block5">
                        <h1 className="l-block4__title">Начнем торговлю криптовалютой сейчас?</h1>
                        <div className="l-block5-form">
                            <input type="text" className="land_sinput" value={emailFromLanding2} onChange={emailFromLanding2Handler} placeholder="Пожалуйста, введите Ваш E-mail"/>
                            <NavLink to={`/register?emailFromLanding=${emailFromLanding2}`} className="start-btn2" >
                                Регистрация
                            </NavLink>
                        </div>
                </div>
            </div>
    );
};

export default Landing;