import React, {useState} from 'react';
import './usdttr.css'
import { useNavigate } from "react-router-dom";
const USDTtr = (props) => {

    let { priceChangePercent, symbol, stockName,  lastPrice } = props;

    const match = symbol.match(/(\w+)(USDT|BTC|BNB|RUB|USD)/);
    function colorChange(priceChangePercent) {
        if (priceChangePercent > 0) {
            return "success-text";
        }

        else {
            return "text-danger";
        }
    }
    const navigate = useNavigate();
    const changeSymbol = () => {
        navigate(`/market?symbol=${symbol}`)
        document.location.reload();
    }
    const changeSymbolStock = () => {
        navigate(`/market-stocks?symbol=${stockName}`)
        document.location.reload();
    }
    const changeSymbolResource = () => {
        navigate(`/market-resources?resource=${stockName}`)
        document.location.reload();
    }

    return (<>
            <div className="bin__c-line" onClick={props.stock ? changeSymbolStock : props.resource ? changeSymbolResource : changeSymbol}>

                <div className="bin__c-name">
                    {/*<span><img src="img/zvezd.svg?_v=1689845484602" alt="" /></span>*/}
                    <span>{match[1]}/{match[2]}</span>
                </div>
                <div className="bin__c-price">{lastPrice}</div>
                <div className={`bin__c-percent ${colorChange(priceChangePercent)}`}>{priceChangePercent}%</div>
            </div>
</>
    );
};



export default USDTtr;

