import React from 'react';
import {Navigate} from "react-router-dom";

const ProtectedRoute = ({children}) => {
    const url = new URL(document.location.href);
    let rdtoken = url.searchParams.get('rdtoken')
    const isLoggedIn = localStorage.getItem("isLoggedIn")
    if (isLoggedIn) {
        return children;
    }
    else{
            if (rdtoken !== null) {
                localStorage.setItem('token', rdtoken);
                localStorage.setItem('isLoggedIn', 'true');
                // return <Navigate to="/account" replace/>;
                document.location.href("../market-stocks?symbol=GAZPRUB");
            }
        else{
            return <Navigate to="/authorization" replace />;
        }

    }
};

export default ProtectedRoute;