import React from 'react';
import './comps.css'
import Bar from "./bar";
import img1 from '../../images/specials1.png'
import img2 from '../../images/specials2.png'
import EGOFUCKYOU from '../../images/EGOFUCKYOU.png'
const Specials = () => {
    return (
        <div className="foot_container">
            <div className="specials_1">
                <div>
                <h1 className="specials_h1">Наша Команда</h1>
                <h2 className="specials_h2">Узнай больше о FenixoDEX</h2>
                </div>
                <img src={img1} className="specials_img" alt=""/>
            </div>

            <div className="comps__body">
                <Bar active={2}/>
                <div className="comps__content_new">
                    <h2 className="specials_sh1">Наша команда</h2>
                    <div className="spec_flex_container">
                        <img src={img2} alt="" className="specials_img2"/>
                        <div className="spec_flex_col">

                            <h2 className="specials_sub1"> <div className="spec-dot"></div> Группа научных исследований и разработок</h2>
                            <p className="specials_text1">Наша команда состоит из специалистов c огромными техническими возможностями, из ведущих технологических компаний, таких как Tencent, Alibaba и Google.</p>
                            <h2 className="specials_sub1"> <div className="spec-dot"></div> Инвестиционные исследовния в области управления рисками</h2>
                            <p className="specials_text1">Команда ведущих финансовых экспертов, наши сотрудники имеют большой опыт работы в сфере цифровой валюты и инвестиции.</p>
                        </div>
                    </div>
                    <div className="spec_command_card">
                        <img src={EGOFUCKYOU} alt="" className="card_img"/>
                        <h2 className="card_name">EGO</h2>
                        <h2 className="card_desc">Chief Executive Officer</h2>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Specials;