import React, {useEffect, useState} from 'react';
import im12 from "../../images/finance-concept-with-hand-holding-smartphone-removebg-preview 1.png";
import './css/asdw.css'
import tis from "./img/tis.svg";
import tisBlack from "./img/tis-black.svg";
import as12 from "./img/as12.svg";
import ss1 from "./img/ss1.svg";
import ss2 from "./img/ss2.svg";
import posts1 from "./img/posts1.svg";
import posts2 from "./img/posts2.svg";
import axios from "axios";
import Transaction from "./transaction";
import GridBalance from "./gridBalance";
import Loading from "../loading/loading";
import {Skeleton} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {amber} from "@mui/material/colors";

const Main = (props) => {



    function number_format(number, decimals, dec_point, thousands_sep) {
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            s = '',
            toFixedFix = function (n, prec) {
                var k = Math.pow(10, prec);
                return '' + Math.round(n * k) / k;
            };
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }

    function createSymbolString(currencyObject) {
        const pairs = Object.keys(currencyObject).map((currency) => {
            return currency.toUpperCase() + "USDT";
        });
        const filteredPairs = pairs.filter((pair) => pair !== "USDTUSDT");
        return filteredPairs.length === 0 ? `symbols=["BNBBTC"]` : `symbols=${JSON.stringify(filteredPairs)}` ;
    }
    function calculateTotalValue(coinsPrice, currencyObject) {
        let totalValue = 0;
        console.log(coinsPrice)

        coinsPrice.forEach((coin) => {
            const { symbol, lowPrice } = coin;
            const currency = symbol === "BNBBTC" ? "usdt" : symbol.replace("USDT", "").toLowerCase();
            const lowpriceNew = symbol === "BNBBTC" ? 1 : lowPrice;
            const rate = (currencyObject[currency] ? currencyObject[currency] : 0);
            const lowPriceNum = parseFloat(lowpriceNew);
            totalValue += lowPriceNum * rate;
            // console.log(`${lowPriceNum} * ${rate},coin:`)
            //console.log(currency)
            // console.log("")
        });
        if(totalValue > 0) {
            return totalValue
        }
        else {
            return(0)
        }
    }






    const token = localStorage.getItem('token');
    const [AVGINVEST, setAVGINVEST] = useState(0);
    const [AVGMAIN, setAVGMAIN] = useState(0);
    const [AVGFUTURES, setAVGFUTURES] = useState(0);
    const [AVGLoaded, setAVGLoaded] = useState(false);

    const [AVGMAINLD, setAVGMAINLD] = useState(false);
    const [AVGINVESTLD, setAVGINVESTLD] = useState(false);
    const [AVGFUTURESLD, setAVGFUTURESLD] = useState(false);

    const [balanceFiltered, setBalanceFiltered] = useState()
    const [bf1l, sbf1l] = useState(false)
    const [bf2l, sbf2l] = useState(false)
    const [bf3l, sbf3l] = useState(false)

    const [balanceFilteredFutures, setBalanceFilteredFutures] = useState()
    const [balanceFilteredInvest, setBalanceFilteredInvest] = useState()

    const [AVGMAINLD2, setAVGMAINLD2] = useState(false);
    const [AVGMAINLD22, setAVGMAINLD22] = useState(false);
    const [AVGFUTURESLD2, setAVGFUTURESLD2] = useState(false);
    const [AVGINVESTLD2, setAVGINVESTLD2] = useState(false);

    const [AVGMAIN2, setAVGMAIN2] = useState(0);
    const [AVGBALANCE2, setAVGBALANCE2] = useState(0);
    const [AVGFUTURES2, setAVGFUTURES2] = useState(0);
    const [AVGINVEST2, setAVGINVEST2] = useState(0);


    const getBalances = () =>{
        axios.get(`https://api.fenixodex.com/api/api/users/balance/common`, {headers: {"Authorization" : `Bearer ${token}`}})
            .then(res => {
                setAVGBALANCE2(Math.floor10(res.data, -2))
                setAVGMAINLD22(true)
            })
        axios.get(`https://api.fenixodex.com/api/api/users/balance/main/common`, {headers: {"Authorization" : `Bearer ${token}`}})
            .then(res => {
                setAVGMAIN2(Math.floor10(res.data, -2))
                setAVGMAINLD2(true)
            })
        axios.get(`https://api.fenixodex.com/api/api/users/balance/futures/common`, {headers: {"Authorization" : `Bearer ${token}`}})
            .then(res => {
                setAVGFUTURES2(Math.floor10(res.data, -2))
                setAVGFUTURESLD2(true)
            })
        axios.get(`https://api.fenixodex.com/api/api/users/balance/invest/common`, {headers: {"Authorization" : `Bearer ${token}`}})
            .then(res => {
                setAVGINVEST2(Math.floor10(res.data, -2))
                setAVGINVESTLD2(true)
            })
    }

    useEffect(() => {
        getBalances()
    }, [])

    function filterKeysWithPositiveValues(obj) {
        const filteredEntries = Object.entries(obj).filter(([key, value]) => value > 0 && key !== 'username_id' && key !== 'id');
        const filteredObject = Object.fromEntries(filteredEntries);
        return filteredObject;
    }
    const requireBalance = () => {
        axios.get(`https://api.fenixodex.com/api/api/users/balance`, {headers: {"Authorization" : `Bearer ${token}`}})
            .then(res => {
                let balanceFilter = filterKeysWithPositiveValues(res.data)
                const keysArray = Object.entries(balanceFilter);
                setBalanceFiltered(keysArray);
                sbf1l(true)
                const apiUrl = `https://api.binance.com/api/v3/ticker/24hr?${createSymbolString(balanceFilter)}`;
                requirePrice(apiUrl, balanceFilter, "main")
            })}

    const requireBalanceInvest = () => {
        axios.get(`https://api.fenixodex.com/api/api/users/balance/invest`, {headers: {"Authorization" : `Bearer ${token}`}})
            .then(res => {
                let balanceFilter = filterKeysWithPositiveValues(res.data)
                const keysArray = Object.entries(balanceFilter);
                setBalanceFilteredInvest(keysArray);
                setAVGINVEST(res.data.rub);
                sbf2l(true)
                setAVGINVESTLD(true)
            })}

    const requireBalanceFutures = () => {
        axios.get(`https://api.fenixodex.com/api/api/users/balance/futures`, {headers: {"Authorization" : `Bearer ${token}`}})
            .then(res => {
                let balanceFilter = filterKeysWithPositiveValues(res.data)
                const keysArray = Object.entries(balanceFilter);
                setBalanceFilteredFutures(keysArray);
                sbf3l(true)
                const apiUrl = `https://api.binance.com/api/v3/ticker/24hr?${createSymbolString(balanceFilter)}`;
                requirePrice(apiUrl, balanceFilter, "futures")

            })}

    const [transactions, setTransactions] = useState([])
    const [trloaded, strloaded] = useState(false)
    const requireTransactions = () => {
        axios.get(`https://api.fenixodex.com/api/api/transaction`, {headers: {"Authorization" : `Bearer ${token}`}})
            .then(res => {
                setTransactions(res.data)
                strloaded(true)

            })}

    const requirePrice = (apiUrl, currencyObject, type) => {
        axios.get(`${apiUrl}`)
            .then(res => {
                const index = res.data.findIndex(n => n.symbol === 'BNBBTC');
                if (index !== -1) {
                    res.data.splice(index, 1);
                }
                res.data.push({
                    "symbol": "USDTUSDT",
                    "priceChange": "0",
                    "priceChangePercent": "0",
                    "prevClosePrice": "1",
                    "lastPrice": "1",
                    "lastQty": "1",
                    "bidPrice": "1",
                    "bidQty": "1",
                    "askPrice": "1",
                    "askQty": "1",
                    "openPrice": "1",
                    "highPrice": "1",
                    "lowPrice": "1",
                    "volume": "1",
                    "quoteVolume": "1",
                    "openTime": 1691438885050,
                    "closeTime": 1691525285050,
                    "firstId": 3186584607,
                    "lastId": 3187361291,
                    "count": 776685
                })
                const newArray = res.data
                const totalValue = calculateTotalValue(newArray, currencyObject)

                switch (type) {
                    case "main":
                        setAVGMAIN(totalValue)
                        setAVGMAINLD(true)
                        break;
                    case "invest":
                        setAVGINVEST(totalValue)
                        setAVGINVESTLD(true)
                        break;
                    case "futures":
                        setAVGFUTURES(totalValue)
                        setAVGFUTURESLD(true)
                        setAVGLoaded(true)
                        break;
                }
            })
    }

    const [switcher, setSwitch] = useState(0)
    const balanceSwitcher = (x) => {
        let mainBtn = document.getElementById("mainBtn")
        let investBtn = document.getElementById("investBtn")
        let futuresBtn = document.getElementById("futuresBtn")

        switch (x) {
            case 0:
                mainBtn.classList.remove("active__ai")
                investBtn.classList.remove("active__ai")
                futuresBtn.classList.remove("active__ai")
                setSwitch(0)
                break;
            case 1:
                mainBtn.classList.add("active__ai")
                investBtn.classList.remove("active__ai")
                futuresBtn.classList.remove("active__ai")
                setSwitch(1)
                break;
            case 2:
                mainBtn.classList.remove("active__ai")
                investBtn.classList.add("active__ai")
                futuresBtn.classList.remove("active__ai")
                setSwitch(2)
                break;
            case 3:
                mainBtn.classList.remove("active__ai")
                investBtn.classList.remove("active__ai")
                futuresBtn.classList.add("active__ai")
                setSwitch(3)
                break;
        }
    }
    (function() {
        function decimalAdjust(type, value, exp) {
            if (typeof exp === 'undefined' || +exp === 0) {
                return Math[type](value);
            }
            value = +value;
            exp = +exp;
            if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
                return NaN;
            }
            value = value.toString().split('e');
            value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
            value = value.toString().split('e');
            return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
        }

        if (!Math.floor10) {
            Math.floor10 = function(value, exp) {
                return decimalAdjust('floor', value, exp);
            };
        }
    })();

    const [cursCb, setCursCb] = useState(90);

    const cbc = () => {
        axios.get(`https://www.cbr-xml-daily.ru/daily_json.js`)
            .then(res => {

                setCursCb(res.data.Valute.USD.Value)
            })
    }




    useEffect( () => {
        requireBalance()
    }, [])

    useEffect( () => {
        requireBalanceFutures()
    }, [])

    useEffect( () => {
        requireBalanceInvest()
    }, [])
    useEffect( () => {
        requireTransactions()

    }, [])
    useEffect( () => {
        cbc()
    }, [])

    const theme = createTheme({
        palette: {
            primary: {
                main: amber[900],
            }
        },
    });
    return (
        <div className="tabs__body">



            <div className="account__box">
                <div className="account__b-top">
                    <div className="account__b-top-left">
                        <div className="account__balans">Общий баланс</div>
                        <div className="account__balans-value" id="myBtn">
                            <i id="faPlus" className="bx bx-show" />
                            <span className="SPN skam" id="AVG">
                                { AVGMAINLD22 ? `$${number_format( AVGMAINLD22 ? AVGBALANCE2 : 0 , 2)}` : <ThemeProvider theme={theme}><CircularProgress color="primary" /></ThemeProvider>}
                      </span>
                        </div>
                    </div>
                    <div className="account__image">
                        <img src={im12} alt="" />
                    </div>
                </div>

                <div className="account__flex">
                    <div className="account__item" id="mainBtn" onClick={
                        () => {
                            if (switcher === 1) {
                                balanceSwitcher(0)
                            }
                            else {
                                balanceSwitcher(1)
                            }}}>
                        <div className={`account__i-top ${ switcher === 1 ? "account__i-top-black" : ""}`}>
                            <img src={ switcher === 1 ? tisBlack : tis} alt="" />


                            <span>Пополнение</span>
                        </div>
                        <div className={`account__i-value ${ switcher === 1 ? "account__i-top-black" : ""}`} id="AVGMAIN">${number_format(AVGMAIN2 > 0 && AVGMAINLD2  ? AVGMAIN2 : 0, 2)} </div>
                    </div>

                    <div className="account__item" id="investBtn" onClick={
                        () => {
                            if (switcher === 2) {
                                balanceSwitcher(0)
                            }
                            else {
                                balanceSwitcher(2)
                            }}}>
                        <div className={`account__i-top ${ switcher === 2 ? "account__i-top-black" : ""}`}>
                            <img src={ switcher === 2 ? tisBlack : tis} alt="" />
                            <span>Инвестиционный счёт</span>
                        </div>
                        <div className={`account__i-value ${ switcher === 2 ? "account__i-top-black" : ""}`} id="AVGINVEST">₽{number_format(AVGINVEST2 > 0 && AVGINVESTLD2  ? AVGINVEST2 : 0, 2)}</div>
                    </div>

                    <div className="account__item" id="futuresBtn" onClick={
                        () => {
                            if (switcher === 3) {
                                balanceSwitcher(0)
                            }
                            else {
                                balanceSwitcher(3)
                            }}}>
                        <div className={`account__i-top ${ switcher === 3 ? "account__i-top-black" : ""}`}>
                            <img src={ switcher === 3 ? tisBlack : tis} alt="" />
                            <span>Счёт фьючерсов</span>
                        </div>
                        <div className={`account__i-value ${ switcher === 3 ? "account__i-top-black" : ""}`} id="AVGFUTURES">${number_format(AVGFUTURES2 > 0 && AVGFUTURESLD2 ? AVGFUTURES2 : 0, 2)} </div>
                    </div>
                </div>


                <div className="account__flex3">
                    <div className={`balance__list ${switcher == 1 || switcher == 2 || switcher == 3 ? "" : "hidden"}`}>
                        { bf1l && switcher == 1 ?  balanceFiltered.map(([key, value]) => (<GridBalance coin={key} value={value}/> ))  : ""}
                        { bf2l && switcher == 2 ?  balanceFilteredInvest.map(([key, value]) => (<GridBalance coin={key} value={value}/>))  : ""     }
                        { bf3l && switcher == 3 ?  balanceFilteredFutures.map(([key, value]) => (<GridBalance coin={key} value={value}/>))  : ""     }
                    </div>
                </div>

                <div className="account__story">
                    <div className="account__s-title-big">История транзакций</div>
                    <div className="account__ss-top">
                        <div className="account__s-title">
                            <span>Валюта</span>
                            <span>
                        <img src={as12} alt="" />
                      </span>
                        </div>
                        <div className="account__s-title">
                            <span>Действие</span>
                            <span>
                        <img src={as12} alt="" />
                      </span>
                        </div>
                        <div className="account__s-title">
                            <span>ID транзакции</span>
                            <span>
                        <img src={as12} alt="" />
                      </span>
                        </div>
                        <div className="account__s-title">
                            <span>Сумма</span>
                            <span>
                        <img src={as12} alt="" />
                      </span>
                        </div>
                        <div className="account__s-title">
                            <span>Дата / Время</span>
                            <span>
                        <img src={as12} alt="" />
                      </span>
                        </div>
                        <div className="account__s-title">
                            <span>Статус</span>
                            <span>
                        <img src={as12} alt="" />
                      </span>
                        </div>
                    </div>
                    <div className="account__s-body">

                        { trloaded ?  transactions.map( trs => {
                            return(<Transaction comment_rejected={trs.comment_rejected} isBankRefill={trs.isBankRefill} cb={cursCb} iclink={trs.icon_link} card={trs.currency_type} action={trs.action} id={trs.id} value={trs.value} time={trs.date} status={trs.status} />)
                        }) : ""}


                    </div>
                    <div className="account__posts">
                        <a href="#" className="account__posts-btn">
                            <img src={posts1} alt="" />
                        </a>
                        <div className="account__posts-nav">
                            <a className="primary" href="">
                                1
                            </a>
                            <a href="">2</a>
                            <a href="">3</a>
                            <a href="">4</a>
                        </div>
                        <a href="#" className="account__posts-btn">
                            <img src={posts2} alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Main;