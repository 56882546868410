import React, {useEffect, useState} from 'react';
import axios from "axios";
import {closeSnackbar, enqueueSnackbar} from "notistack";
import CloseIcon from "@mui/icons-material/Close";

const PortfolioStocks = () => {
        const action = snackbarId => (
        <button onClick={() => { closeSnackbar(snackbarId) }}>
            <CloseIcon/>
        </button>
    );

    const token = localStorage.getItem('token');
    const [availablePortfolioActive, setavailablePortfolioActive] = useState(true)
    const [activePortfolioActive, setactivePortfolioActive] = useState(true)
    const [availablePortfolios, setAvailablePortfolios] = useState([])
    const [activePortfolios, setActivePortfolios] = useState([])
    const getAvailablePortfolios = () => {
        axios.get("https://api.fenixodex.com/api/api/share/portfolios", {headers: {Authorization: `Bearer ${token}`}})
            .then(res => {
                setAvailablePortfolios(res.data)
            })
            .catch(error => {
                console.error(error)
            })
    }

    const getActivePortfolios = () => {
        axios.get("https://api.fenixodex.com/api/api/share/portfolios/active", {headers: {Authorization: `Bearer ${token}`}})
            .then(res => {
                setActivePortfolios(res.data)
            })
            .catch(error => {
                console.error(error)
            })
    }
    const handleBuyPortfolio = (id) => {
        axios.post("https://api.fenixodex.com/api/api/share/portfolio", {id},
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    )
    .
        then(res => {
            enqueueSnackbar(`Успешно!`, {action, 'variant': 'success', 'className': "snacksuccess"});
            getAvailablePortfolios();
            getActivePortfolios();
        })
            .catch(error => {
                enqueueSnackbar(`Возникла ошибка: ${error.response.data.detail}`, {
                    action,
                    'variant': 'error',
                    'className': "snackerror"
                });
            });
    }


    useEffect(() => {
        getAvailablePortfolios()
        getActivePortfolios()
    }, [])
    return (
        <div className="tabs__body">
            <div className="account__box">
                <div className="deposits__body">
                    <div className="account__balans">Портфель</div>
                    <div className="portfolio__attention2 pointer" onClick={() => setavailablePortfolioActive(!availablePortfolioActive)}>
                        <h1 className="portfolio__active-title portfolio-title">ДОСТУПНЫЕ ПОРТФЕЛИ
                        <span className={`portfolio-arrow ${availablePortfolioActive ? "portfolio-arrow-active" : ""}`}>
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.0655 16.5938L21.3624 7.88602C21.5009 7.74073 21.5781 7.5477 21.5781 7.34696C21.5781 7.14623 21.5009 6.95319 21.3624 6.8079L21.353 6.79852C21.2858 6.72785 21.205 6.67156 21.1154 6.63311C21.0258 6.59465 20.9294 6.57481 20.8319 6.57481C20.7344 6.57481 20.6379 6.59465 20.5483 6.63311C20.4588 6.67156 20.3779 6.72785 20.3108 6.79852L12.4983 14.9985L4.68891 6.79852C4.62177 6.72784 4.54095 6.67156 4.45136 6.6331C4.36178 6.59464 4.26531 6.57481 4.16782 6.57481C4.07033 6.57481 3.97386 6.59464 3.88428 6.6331C3.7947 6.67156 3.71387 6.72784 3.64673 6.79852L3.63735 6.8079C3.49884 6.95319 3.42158 7.14623 3.42158 7.34696C3.42158 7.5477 3.49884 7.74073 3.63735 7.88602L11.9342 16.5938C12.0072 16.6704 12.0949 16.7314 12.1922 16.773C12.2894 16.8147 12.3941 16.8362 12.4999 16.8362C12.6056 16.8362 12.7103 16.8147 12.8075 16.773C12.9048 16.7314 12.9925 16.6704 13.0655 16.5938Z" fill="#FE7701FF"/>
                            </svg>
                        </span>
                        </h1>
                    </div>
                        {
                            availablePortfolioActive
                                ? <div className="available-portfolio-container">
                            {
                                availablePortfolios.map(portfolio =>
                                <div className="available-portfolio-item">

                                     { portfolio.multiple
                                                ? <div className="stock-container-2">
                                                    <h1 className="portfolio-item-title">{portfolio.name}</h1>
                                                    <div className="stock-container">
                                                        <div className="portfolio-item-sub-si-stock">Акция</div>
                                                        <div className="portfolio-item-sub-si">Пакет</div>
                                                        <div className="portfolio-item-sub-si">Сумма</div>
                                                    </div>
                                                  </div>
                                                : <h1 className="portfolio-item-title">{portfolio.name} <span className="portfolio-stock-name">{portfolio.stockname}</span></h1> }
                                            {
                                                portfolio.multiple ?
                                                    (<div className="container-ultra">
                                                        {portfolio.stocks.map(stock =>
                                                            <div className="stock-container">
                                                                <h1 className="portfolio-item-title-si">{stock.name} <span className="portfolio-stock-name-si">{stock.stockname}</span></h1>
                                                                <p className="portfolio-item-sub-si">{stock.amount} шт</p>
                                                                <p className="portfolio-item-sub-si">{Math.floor10(stock.price, -1)} RUB</p>
                                                            </div>
                                                        )}
                                                    </div>)
                                                    :
                                                    <div className="pis-container">
                                                        <p className="portfolio-item-sub">Ожидаемый APY - {portfolio.APY}%</p>
                                                        <p className="portfolio-item-sub">Колво акций - {portfolio.amount} шт</p>
                                                        <p className="portfolio-item-sub">Срок - {portfolio.deadline} дней</p>
                                                    </div>
                                            }

                                <h1 className="portfolio-item-title">CТОИМОСТЬ: {portfolio.price} RUB</h1>
                                    {portfolio.multiple
                                        ? <div className="portfolio-container-button">
                                            <p className="portfolio-item-subtext">APY: {portfolio.APY}%</p>
                                            <button className="portfolio-item-button" onClick={() => handleBuyPortfolio(portfolio.id)}>Активировать</button>
                                            <p className="portfolio-item-subtext">Срок: {portfolio.deadline} дней</p>
                                          </div>
                                        : <button className="portfolio-item-button" onClick={() => handleBuyPortfolio(portfolio.id)}>Активировать</button>}

                            </div>
                                )
                            }

                        </div>
                                : ""
                        }
                    <div className="portfolio__attention2 pointer mt-30" onClick={() => setactivePortfolioActive(!activePortfolioActive)}>
                        <h1 className="portfolio__active-title portfolio-title">АКТИВНЫЕ ПОРТФЕЛИ
                        <span className={`portfolio-arrow ${activePortfolioActive ? "portfolio-arrow-active" : ""}`}>
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.0655 16.5938L21.3624 7.88602C21.5009 7.74073 21.5781 7.5477 21.5781 7.34696C21.5781 7.14623 21.5009 6.95319 21.3624 6.8079L21.353 6.79852C21.2858 6.72785 21.205 6.67156 21.1154 6.63311C21.0258 6.59465 20.9294 6.57481 20.8319 6.57481C20.7344 6.57481 20.6379 6.59465 20.5483 6.63311C20.4588 6.67156 20.3779 6.72785 20.3108 6.79852L12.4983 14.9985L4.68891 6.79852C4.62177 6.72784 4.54095 6.67156 4.45136 6.6331C4.36178 6.59464 4.26531 6.57481 4.16782 6.57481C4.07033 6.57481 3.97386 6.59464 3.88428 6.6331C3.7947 6.67156 3.71387 6.72784 3.64673 6.79852L3.63735 6.8079C3.49884 6.95319 3.42158 7.14623 3.42158 7.34696C3.42158 7.5477 3.49884 7.74073 3.63735 7.88602L11.9342 16.5938C12.0072 16.6704 12.0949 16.7314 12.1922 16.773C12.2894 16.8147 12.3941 16.8362 12.4999 16.8362C12.6056 16.8362 12.7103 16.8147 12.8075 16.773C12.9048 16.7314 12.9925 16.6704 13.0655 16.5938Z" fill="#FE7701FF"/>
                            </svg>
                        </span>
                        </h1>
                    </div>
                    <div className="available-portfolio-container">
                        {
                            activePortfolioActive
                                ?
                                activePortfolios.map( portfolio =>
                                        <div className="available-portfolio-item">
                                            { portfolio.multiple
                                                ?
                                                <div className="stock-container-2">
                                                <h1 className="portfolio-item-title">{portfolio.name}</h1>
                                                <div className="stock-container">
                                                    <div className="portfolio-item-sub-si-stock">Акция</div>
                                                    <div className="portfolio-item-sub-si">Пакет</div>
                                                    <div className="portfolio-item-sub-si">Сумма</div>
                                                </div>
                                                </div>
                                                : <h1 className="portfolio-item-title">{portfolio.name} <span className="portfolio-stock-name">{portfolio.stockname}</span></h1> }
                                            {
                                                portfolio.multiple ?
                                                    (<div className="container-ultra">
                                                        {portfolio.stocks.map(stock =>
                                                            <div className="stock-container">
                                                                <h1 className="portfolio-item-title-si">{stock.name} <span className="portfolio-stock-name-si">{stock.stockname}</span></h1>
                                                                <p className="portfolio-item-sub-si">{stock.amount} шт</p>
                                                                <p className="portfolio-item-sub-si">{Math.floor10(stock.price, -1)} RUB</p>
                                                            </div>
                                                        )}
                                                    </div>)
                                                    :
                                                    <div className="pis-container">
                                                        <p className="portfolio-item-sub">Количество акций:</p>
                                                        <p className="portfolio-item-sub">{portfolio.amount} шт</p>
                                                        <p className="portfolio-item-sub">Начальная ставка:</p>
                                                        <p className="portfolio-item-sub">{portfolio.price} RUB</p>
                                                    </div>
                                            }

                                            <p className="portfolio-item-comment">{portfolio.comment}</p>
                                            {/*<h1 className="portfolio-item-title">ТЕКУЩАЯ ПРИБЫЛЬ: </h1>*/}
                                            {/*<h1 className={`portfolio-item-got ${ portfolio.priceChange > 0 ? "pi-green" : portfolio.priceChangePer = 0 ? "" : "pi-red"}`}>{portfolio.priceChange} RUB ({portfolio.priceChangePercent}%)</h1>*/}
                                        </div>

                                )
                                : ""
                            }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PortfolioStocks;