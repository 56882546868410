import React from 'react';
import {Navigate} from "react-router-dom";

const ProtectedRoute = ({children}) => {
    const isLoggedIn = localStorage.getItem("isLoggedIn")


    if (isLoggedIn) {
        return children;
    }
    else{
        return <Navigate to="/authorization" replace />;
    }
};

export default ProtectedRoute;