import * as actions from './actionTypes';


export default function reducer(state = [], action) {
    switch (action.type) {

        case actions.EMAIL_ADD:
            return [...state, {
                storeEmail: action.payload.storeEmail
            }];

        default:
            return state;
    }
}
