import React from 'react';
import './comps.css'
import abouti from '../../images/infoIMG.svg'
import chelii from '../../images/chelikiIMG.svg'
import privacyi from '../../images/privacyIMG.svg'
import contactsi from '../../images/contacts.svg'
import {NavLink} from "react-router-dom";
const Bar = (props) => {
    return (
        <nav className="comps__bar">
            <NavLink to="/about" className={`bar__item ${props.active === 1 ? "bar__item-active" : ""}`}>
                <img src={abouti} alt="" className="bar__img"/>
                <p className="bar__text">О нас</p>
            </NavLink>

            <NavLink to="/specialists" className={`bar__item ${props.active === 2 ? "bar__item-active" : ""}`}>
                <img src={chelii} alt="" className="bar__img"/>
                <p className="bar__text">Наши специалисты</p>
            </NavLink>

            <NavLink to="/privacy" className={`bar__item ${props.active === 3 ? "bar__item-active" : ""}`}>
                <img src={privacyi} alt="" className="bar__img"/>
                <p className="bar__text">Политика конфиденциальности</p>
            </NavLink>

            <NavLink to="/contacts" className={`bar__item ${props.active === 4 ? "bar__item-active" : ""}`}>
                <img src={contactsi} alt="" className="bar__img"/>
                <p className="bar__text">Контакты</p>
            </NavLink>
        </nav>
    );
};

export default Bar;